<template>
    <QDialog
        v-model="opened"
        @hide="close"
        @show="$nextTick(() => { $refs.name.focus() })"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ isVariant ? $t('visualization.renameVariantModal.title') : $t('visualization.renameLoopModal.title') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="q-pt-none q-px-lg Body">
                <QForm
                    class="align-center q-gutter-md q-ma-md"
                    onSubmit="return false;"
                    @submit="handleRename"
                >
                    <QInput
                        ref="name"
                        v-model="newName"
                        :label="isVariant ? $t('visualization.renameVariantModal.name') : $t('visualization.renameLoopModal.name')"
                        outlined
                        stackLabel
                        dense
                        :rules="[val => !!val || $t('visualization.variantRenameRequired'),
                                 val => (val.length >= 1 && val.length <= 100) || $t('visualization.variantRenameLengthError')]"
                    />
                    <QBtn
                        :label="$t('visualization.renameVariantModal.submit')"
                        noCaps
                        color="primary"
                        unelevated
                        @click="handleRename"
                    />
                </QForm>
            </q-card-section>
        </QCard>
    </QDialog>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'Rename',
    components: {},
    props: {
        isVariant: VueTypes.bool,
    },
    emits: ['save'],
    data () {
        return {
            opened: false,
            newName: undefined,
        }
    },
    methods: {
        focusInput () {
            this.$nextTick(() => {
                this.$refs.name.$el.focus()
            })
        },
        open () {
            this.reset()
            this.opened = true
            this.$nextTick(() => {
                this.focusInput()
            })
        },
        close () {
            this.opened = false
        },
        reset () {
            this.newName = undefined
        },
        handleRename () {
            if (this.$refs.name.validate()) {
                this.$emit('save', {
                    newName: this.newName,
                })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Card .Body {
    min-width: 500px
}
.Header {
    padding-bottom:0px;
}
</style>
