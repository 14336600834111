export default api => ({
    performanceByContent: ({ processId, params }) => api.post(`/datasets/${processId}/performance/performance-by-content`, params),
    performanceComparison: ({ processId, params }) => api.post(`/datasets/${processId}/performance/attribute-performance-comparison`, params),
    leadTimes: ({ processId, params }) => api.post(`/datasets/${processId}/performance/lead-times`, params),
    attributeLeadTimes: ({ processId, params }) => api.post(`/datasets/${processId}/performance/attribute-lead-times`, params),
    evolutionOverTime: ({ processId, params }) => api.post(`/datasets/${processId}/performance/evolution-over-time`, params),
    favouriteAttributes: ({ processId, params }) => api.post(`/datasets/${processId}/performance/favourite-attributes`, params),
    overall: ({ processId, params }) => api.post(`/datasets/${processId}/performance/overall`, params),
    overallNumericAttributes: ({ processId, params }) => api.post(`/datasets/${processId}/performance/overall-numeric`, params),
    stateD2T: ({ processId, params }) => api.post(`/datasets/${processId}/performance/state-d2t`, params),
    // Prediction
    highlights: ({ processId }) => api.post(`/datasets/${processId}/performance/highlights`),
    predictedPerformanceDuration: ({ processId, params }) => api.post(`/datasets/${processId}/performance/predicted-duration`, params),
    predictedPerformanceRisk: ({ processId, params }) => api.post(`/datasets/${processId}/performance/predicted-risk`, params),
    predictedPerformanceDistribution: ({ processId, params }) => api.post(`/datasets/${processId}/performance/predicted-performance`, params),
    timeToMeet: ({ processId, params }) => api.post(`/datasets/${processId}/performance/time-to-meet`, params),
    ongoing: ({ processId, params }) => api.post(`/datasets/${processId}/performance/ongoing`, params),
    predictionD2T: ({ processId, params }) => api.post(`/datasets/${processId}/performance/prediction-d2t`, params),
    // Improvement
    activitiesTransition: ({ processId, params }) => api.post(`/datasets/${processId}/whatif/activities-transitions`, params),
    overallImprovement: ({ processId, params }) => api.post(`/datasets/${processId}/whatif/overall-improvement`, params),
    whatIfD2T: ({ processId, params }) => api.post(`/datasets/${processId}/performance/whatif-d2t`, params),
    // sla info
    slaInfo: ({ processId, params }) => api.post(`/datasets/${processId}/sla-info`, params),
})
