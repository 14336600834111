<template>
    <QDialog
        v-model="open"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ $t('visualization.filters.filterSets.createNewFilterSet') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body q-pt-none q-px-lg">
                <QForm
                    class="align-center"
                    @submit.prevent="handleSubmit"
                >
                    <div class="row mt-1">
                        <QInput
                            v-model="filterSetName"
                            dense
                            outlined
                            style="width: 100%;"
                            :label="$t('visualization.filters.filterSets.search.placeholder')"
                        />
                    </div>

                    <div class="q-pt-md">
                        <WText
                            tag="h4"
                            :size="14"
                            align="left"
                            weight="bold"
                        >
                            {{ $t('visualization.filters.filterSets.config.aggregationOperators.title') }}
                        </WText>
                        <div class="q-gutter-sm">
                            <QRadio
                                v-model="operator"
                                val="AND"
                                :label="$t('visualization.filters.filterSets.config.aggregationOperators.AND')"
                            />
                            <QRadio
                                v-model="operator"
                                val="OR"
                                :label="$t('visualization.filters.filterSets.config.aggregationOperators.OR')"
                            />
                        </div>
                    </div>

                    <div class="justify-end row q-my-lg">
                        <QBtn
                            noCaps
                            color="primary"
                            type="submit"
                            :label="$t('actions.create')"
                        />
                    </div>
                </QForm>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersStorageMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'

export default {
    name: 'CreateFilterSetsModal',
    components: {},
    mixins: [filtersStorageMixin],
    props: {
        datasetId: VueTypes.string,
        activeFilters: VueTypes.array,
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            filterSetName: '',
            operator: 'AND',
            open: false,
        }
    },
    computed: {
        operators () {
            return [
                {
                    label: this.$t('visualization.filters.filterSets.config.aggregationOperators.AND'),
                    value: 'AND',
                },
                {
                    label: this.$t('visualization.filters.filterSets.config.aggregationOperators.OR'),
                    value: 'OR',
                },
            ]
        },
    },
    watch: {
        activeFilters: {
            handler () {
                this.updateOperator()
            },
            deep: true,
        },
        isOpen (open) {
            this.open = open
            if (open) {
                this.filterSetName = ''
                this.updateOperator()
            }
        },
    },
    mounted () {
        this.updateOperator()
    },
    methods: {
        close () {
            this.$emit('close')
        },
        handleSubmit () {
            this.registerDatasetFilters()
            this.setGeneralOperator(this.operator)
            this.$emit('repeatCalls')
            this.close()
        },
        registerDatasetFilters () {
            const { datasetId } = this
            const name = this.filterSetName
            const { operator } = this
            const filters = this.formatFiltersParam(this.activeFilters)
            const params = { filters, name, operator }
            this.isLoading = true
            apiRequest(Api().datasets.registerDatasetFilters({ datasetId, params }))
                .then((res) => {
                    notifySuccess(this.$t('visualization.filters.filterSets.register.success'))
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        updateOperator () {
            const { generalOperator } = this.splitFilterAndFilterSets(this.activeFilters)
            this.operator = generalOperator || 'AND'
        },
    },
}
</script>

<style scoped lang="scss">
.ApplyButton {
    margin-top: 1rem;
    width: 100%;
}
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
.Card {
    overflow:hidden;
    flex-wrap:nowrap;
}
.Header {
    position:sticky;
    padding-bottom:0px;
}
.Body {
    overflow-y:auto;
}
</style>
