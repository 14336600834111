<template>
    <QCard class="flex column Card">
        <q-card-section class="row q-pa-lg items-center Header">
            <WText
                class="q-mb-sm"
                :size="18"
            >
                {{ $t('booster.alerts.definition.configureAlert') }}: {{ selectedNode?.name }}
            </WText>
            <q-space />
            <q-btn
                v-close-popup
                icon="close"
                flat
                round
                dense
                @click="cancel"
            />
        </q-card-section>
        <q-card-section class="q-px-lg">
            <QForm
                ref="alertForm"
                @submit.prevent="update"
            >
                <div
                    class="Box row q-pa-md items-center"
                >
                    <div
                        class="flex row"
                    >
                        <div>
                            <WText>{{ $t('booster.alerts.definition.name') }}</WText>
                            <QInput
                                ref="name"
                                v-model="nameFld"
                                outlined
                                dense
                                class="q-mt-sm"
                                disable
                                lazyRules
                                stackLabel
                                maxlength="100"
                                :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('booster.alerts.definition.name') })]"
                            />
                        </div>
                        <div class="SeverityContainer">
                            <WText>{{ $t('booster.alerts.definition.severity') }} </WText>
                            <QBtnToggle
                                v-model="severityTgl"
                                :toggleColor="getSeverityColor(severityTgl)"
                                toggleTextColor="black"
                                push
                                disable
                                unelevated
                                noCaps
                                :options="severityOptions"
                                class="q-my-sm"
                            />
                        </div>
                    </div>
                    <div
                        class="col-sm-12"
                    >
                        <AlertTriggers
                            :configure="true"
                            :configureTriggers="triggers"
                        />
                    </div>
                </div>
                <div class="q-pt-xs">
                    <AlertsNotifications
                        :currentData="currentData"
                        @updateSendData="updateSendData"
                    />
                </div>
                <div class="flex q-gutter-md justify-end q-pa-md">
                    <QBtn
                        :label="$t('booster.alerts.definition.createScreen.cancel')"
                        color="primary"
                        noCaps
                        outline
                        @click="cancel"
                    />
                    <QBtn
                        type="submit"
                        :label="$t('booster.alerts.definition.createScreen.updateAlert')"
                        color="primary"
                        noCaps
                        :disable="!saveBtnStatus"
                    />
                </div>
            </QForm>
        </q-card-section>
    </QCard>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import {
    notifyError, notifySuccess,
    Api, apiRequest,
} from '@/api'
import AlertTriggers from './AlertTriggers.vue'
import AlertsNotifications from './AlertsNotifications.vue'

export default {
    name: 'ConfigureAlert',
    components: {
        AlertsNotifications,
        AlertTriggers,
    },
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        selectedNode: VueTypes.oneOfType([VueTypes.object, VueTypes.array]),
        selectedSeverityNode: VueTypes.oneOfType([VueTypes.string, VueTypes.array]),
    },
    emits: ['updatedAlert', 'cancel'],
    data () {
        return {
            nameFld: this.selectedNode?.name,
            severityTgl: this.selectedSeverityNode,
            sendTgl: undefined,
            saveBtnStatus: true,
            period: this.$t('booster.alerts.definition.period'),
            unit: {
                value: 'minutes',
                label: this.$t('booster.alerts.definition.timeUnit.minutes'),
            },
            notifications: {},
            triggers: [],
            currentData: {},
        }
    },
    computed: {
        severityOptions () {
            return [{
                value: 'LOW',
                label: this.$t('booster.alerts.detected.severityRiskLabels.LOW'),
            }, {
                value: 'MEDIUM',
                label: this.$t('booster.alerts.detected.severityRiskLabels.MEDIUM'),
            }, {
                value: 'HIGH',
                label: this.$t('booster.alerts.detected.severityRiskLabels.HIGH'),
            }]
        },
    },
    beforeMount () {
    },
    mounted () {
        this.isLoading = true
        this.getCurrentData()
    },
    methods: {
        updateSendData (data) {
            this.notifications = data
        },
        getSeverityColor (severity) {
            const colors = {
                LOW: 'amber-11',
                MEDIUM: 'orange-11',
                HIGH: 'red-11',
            }
            return colors[severity]
        },
        setDefaultSeverity () {
            this.severityTgl = 'LOW'
        },
        cancel () {
            this.$emit('cancel')
        },
        hasValidationErrors () {
            if (this.notifications.mailToCreatingUser === false) {
                if (this.notifications.mailToNotify) {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    return emailRegex.test(this.notifications.mailToNotify)
                }
                return !(this.notifications.mailToNotify === '' || this.notifications.mailToNotify === null)
            }
            return !(this.nameFld === undefined || this.nameFld === '' || Object.keys(this.triggers).length === 0)
        },
        update () {
            if (this.hasValidationErrors()) {
                this.saveBtnStatus = false
                const params = this.notifications
                const datasetId = this.processId
                const alertId = this.selectedNode.id

                apiRequest(Api().alerts.update({ datasetId, alertId, params }))
                    .then(() => {
                        this.$emit('updatedAlert')
                        notifySuccess(this.$t('booster.alerts.notifications.updated'))
                    })
                    .catch(notifyError)
                    .finally(() => (
                        this.cancel()
                    ))
            }
        },
        getCurrentData () {
            const datasetId = this.processId
            const alertId = this.selectedNode.id
            apiRequest(Api().alerts.get({ datasetId, alertId }))
                .then((data) => {
                    this.currentData = {
                        sendTgl: data.mailToCreatingUser,
                        emailAdrs: data.mailToNotify,
                        frequency: data.frequencyNotification,
                        day: data.weekDayNotification,
                        time: data.timeNotification !== null ? moment(data.timeNotification, "HH:mm:ss").format("HH:mm") : null,
                    }
                    this.severityTgl = data.severity
                    this.triggers = data.filters
                })
                .catch(notifyError)
        },
    },
}
</script>
<style scoped lang="scss">
.Box {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
.Cancel {
    cursor:pointer;
}
.SeverityContainer {
    margin-left: 5vw;
}
.Header {
    padding-bottom:0px;
}
</style>
