<template>
    <div class="DatasetsListCards">
        <Table
            ref="table"
            :data="normalizeRows"
            :loading="loading"
            :total="total"
            hideHeader
            rowKey="uuid"
            @table-request="handleFetchData"
        >
            <template #content="{ row }">
                <DatasetCard
                    :row="row"
                    @handle-emmit="handleEmmit($event, row)"
                />
            </template>
            <template #empty>
                <EmptyList
                    v-bind="emptyMessage"
                    class="mt-2 mb-2"
                >
                    <template #footer>
                        <QBtn
                            class="mt-1"
                            color="primary"
                            noCaps
                            :label="$t('projects.datasets.new')"
                            icon="add_circle_outline"
                            :disable="archived"
                            @click="$emit('onNewItem')"
                        />
                    </template>
                </EmptyList>
            </template>
        </Table>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersMixin } from '@/mixins'
import { Table, EmptyList } from '@/components'
import DatasetCard from './DatasetCard.vue'

const ACTIONS = {
    DELETE: 'DELETE',
    EDIT: 'EDIT',
    DISCOVERY: 'DISCOVERY',
    QUERY: 'QUERY',
    CONFORMANCE: 'CONFORMANCE',
    MONITORING: 'MONITORING',
    INFO: 'INFO',
    MOVE: 'MOVE',
}

const ACTION_TYPES = {
    PROCESSING: 'PROCESSING',
    UPLOADED: 'UPLOADED',
    WITHOUT_ACTION: 'WITHOUT_ACTION',
}

export default {
    name: 'DatasetsListCards',
    components: {
        DatasetCard,
        Table,
        EmptyList,
    },
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.number,
        search: VueTypes.string,
        loading: VueTypes.bool.def(false),
        archived: VueTypes.bool.def(false),
    },
    emits: ['onNewItem', 'onDelete', 'onUpload', 'onFetchData', 'onVisualization', 'onSettings', 'onMove'],
    computed: {
        emptyMessage () {
            return {
                title: this.$t(`projects.datasets.notifications.${this.search ? 'noResult.title' : 'empty.title'}`),
                text: this.$t(`projects.datasets.notifications.${this.search ? 'noResult.text' : 'empty.text'}`),
            }
        },
        normalizeRows () {
            return (this.rows || []).map(row => ({
                ...row,
                options: this.generateOptionsRow(row),
            }))
        },
    },
    beforeMount () {
        this.ACTION_TYPES = ACTION_TYPES
    },
    methods: {
        generateOptionsRow () {
            return {
                dropdown: [
                    {
                        key: ACTIONS.INFO, icon: 'settings', iconColor: 'primary', label: this.$t('actions.info'), event: 'onSettings',
                    },
                    {
                        key: ACTIONS.MOVE, icon: 'folder', iconColor: 'primary', label: this.$t('actions.move'), event: 'onMove',
                    },
                    {
                        key: ACTIONS.DELETE, icon: 'delete', iconColor: 'primary', label: this.$t('actions.delete'), event: 'onDelete',
                    },
                ],
            }
        },
        handleEmmit (event, row) {
            console.log(event, row)
            this.$emit(event, row)
        },
        handleFetchData (props) {
            this.$emit('onFetchData', props)
        },
        getPagination () {
            return this.$refs.table.getPagination()
        },
        isArchived ({ deletedAt }) {
            return !!deletedAt
        },
    },
}
</script>
<style scoped lang="scss">
.Table:deep() {
  border: none !important;
  .q-table__bottom {
    margin-top: 20px;
    border: 1px solid $table-border-color;
  }
  tbody {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 16px;
    @media screen and (min-width: $md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (min-width: $xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media screen and (min-width: $xxxl) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
</style>
