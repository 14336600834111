<template>
    <div class="Preview">
        <div class="flex row-stretch-top Top">
            <Tracking
                :step="step"
                :length="(fields || []).length"
                class="flex-1 mb-1"
            />
            <div class="Options flex column">
                <div class="flex row justify-between">
                    <div class="flex row-center">
                        <WText>
                            {{ $t('wizard.preview.homogeinzeAttribute') }}
                        </WText>
                        <QToggle
                            v-model="homogenizeAttr"
                            color="primary"
                            keepColor
                            @update:modelValue="handleChangeHomogenizeAttr"
                        />
                    </div>
                    <div class="flex row-center">
                        <WText>
                            {{ $t('wizard.preview.numberFormat.label') }}
                        </WText>
                        <QSelect
                            ref="numberFormat"
                            v-model="numberFormat"
                            dense
                            outlined
                            behavior="menu"
                            class="Select"
                            :options="numberFormats"
                            @update:modelValue="handleChangeNumberFormat"
                        >
                            <template #option="scope">
                                <QItem
                                    v-bind="scope.itemProps"
                                    v-on="scope.itemEvents || {}"
                                >
                                    <QItemSection>
                                        <QItemLabel>{{ scope.opt.label }}</QItemLabel>
                                        <QItemLabel caption>
                                            {{ scope.opt.description }}
                                        </QItemLabel>
                                    </QItemSection>
                                </QItem>
                            </template>
                        </QSelect>
                    </div>
                </div>
                <div
                    v-if="showNumberFormatWarning"
                    class="Warning flex row-right-center"
                >
                    <span class="WarningTextBold">
                        {{ $t('wizard.preview.warning') }}
                    </span>
                    <span class="WarningText">
                        {{ $t('wizard.preview.mismatchedNumberFormat') }}
                    </span>
                </div>
            </div>
        </div>
        <QTable
            bordered
            flat
            :columns="normalizeColumns"
            :rows="rows"
            :loading="loading"
            :pagination="pagination"
            hideBottom
            rowKey="index"
            separator="cell"
            class="table-list"
        >
            <template #header="props">
                <QTr
                    v-if="selectable"
                    :props="props"
                    class="FakeRow"
                >
                    <QTh
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        <div
                            v-if="col.checked || col.hovered"
                            class="Field flex row-stretch-center"
                            :class="{ optional: (fields[col.checkedIndex] || fields[col.hoveredIndex] || {}).optional}"
                        >
                            <WText
                                weight="semi"
                                tag="span"
                                color="white"
                                :size="14"
                            >
                                {{ (fields[col.checkedIndex] || fields[col.hoveredIndex] || {}).label }}
                            </WText>
                            <q-icon
                                v-if="((fields[col.checkedIndex] ||
                                    fields[col.hoveredIndex] || {}).label === $t('wizard.activity')) &&
                                    !col.selectableAsActivity"
                                class="Alert"
                                color="#ffec54"
                                size="20px"
                                name="alert-triangle"
                            />
                            <QTooltip
                                v-if="(fields[col.checkedIndex] || fields[col.hoveredIndex] || {}).label === $t('wizard.activity') &&
                                    !col.selectableAsActivity"
                                class="bg-yellow text-black text-body2"
                                maxWidth="300px"
                            >
                                {{ $t('wizard.activityWarning') }}
                            </QTooltip>
                            <q-icon
                                size="18px"
                                name="close"
                                color="white"
                                clickable
                                style="cursor: pointer;"
                                @click="handleClickRow({ col }, false)"
                            />
                        </div>
                    </QTh>
                </QTr>
                <QTr :props="props">
                    <QTh
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        class="Header"
                        :class="{
                            hovered: hovered === col.field,
                            cursor: col.checked,
                            disabled: !col.checked && disabledChecked
                        }"
                        @mouseover="handeleRowMouseOver({ col }, $event)"
                        @mouseleave="handleRowMouseEnter({ col }, $event)"
                        @click="handleClickRow({ col }, $event)"
                    >
                        {{ col.label }}
                    </QTh>
                </QTr>
            </template>

            <template #body-cell="props">
                <QTd
                    :props="props"
                    class="Item"
                    :class="{
                        hovered: hovered === props.col.field,
                        cursor: props.col.checked,
                        disabled: !props.col.checked && disabledChecked
                    }"
                    @mouseover="handeleRowMouseOver(props, $event)"
                    @mouseleave="handleRowMouseEnter(props, $event)"
                    @click="handleClickRow(props, $event)"
                >
                    {{ props.value }}
                </QTd>
            </template>
            <template #bottom>
                <QTr>
                    <QTh />
                </QTr>
            </template>
        </QTable>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import Config from '@/config'
import Tracking from './Tracking.vue'

const FIELD_KEYS = {
    TRACE: 'traceId',
    ACTIVITY: 'activity',
    START: 'start',
    END: 'end',
}

const LIMIT = 4

export default {
    name: 'Preview',
    components: {
        Tracking,
    },
    inject: ['App'],
    props: {
        modelValue: VueTypes.any,
        columns: VueTypes.any,
        rows: VueTypes.any,
        loading: VueTypes.bool.def(false),
        selectable: VueTypes.bool.def(true),
    },
    emits: ['update:modelValue'],
    data () {
        const { user } = this.App
        const numberFormats = this.getNumberFormats()
        return {
            hovered: undefined,
            checkeds: [],
            fieldKeys: FIELD_KEYS,
            thousandCharacter: '',
            decimalCharacter: '',
            pagination: {
                page: 0,
                rowsPerPage: 0,
            },
            homogenizeAttr: false,
            user: { ...user },
            numberFormat: numberFormats.find(({ value }) => value === 'en') || 'en',
            numberFormats,
        }
    },
    computed: {
        disabledChecked () {
            return this.checkeds.filter(col => !!col).length >= LIMIT
        },
        normalizeColumns () {
            return (this.columns || []).map((col) => {
                const { field } = col
                const hovered = this.hovered === field && !this.disabledChecked
                const hoveredIndex = hovered ? this.indexEmpty() : undefined
                const checked = this.isChecked(field)
                const checkedIndex = checked ? this.indexChecked(field) : undefined
                return {
                    ...col,
                    hovered,
                    hoveredIndex,
                    checked,
                    checkedIndex,
                }
            })
        },
        step () {
            const numberOfSteps = this.checkeds.filter(Boolean).length
            return !this.disabledChecked
                ? {
                    num: numberOfSteps + 1,
                    ...this.fields[this.indexEmpty()],
                }
                : undefined
        },
        fields () {
            return [
                {
                    key: FIELD_KEYS.TRACE,
                    label: this.$t('wizard.trace'),
                    tooltip: this.$t('wizard.preview.tooltips.TRACE'),
                },
                {
                    key: FIELD_KEYS.ACTIVITY,
                    label: this.$t('wizard.activity'),
                    tooltip: this.$t('wizard.preview.tooltips.ACTIVITY'),
                },
                {
                    key: FIELD_KEYS.START,
                    label: this.$t('wizard.startTime'),
                    tooltip: this.$t('wizard.preview.tooltips.START'),
                },
                {
                    key: FIELD_KEYS.END,
                    label: this.$t('wizard.endTime'),
                    optional: true,
                    tooltip: this.$t('wizard.preview.tooltips.END'),
                },
            ]
        },
        showNumberFormatWarning () {
            const { locationFormat } = this.user
            return this.numberFormat.value !== locationFormat
        },
    },
    mounted () {
        this.initialize()
    },
    methods: {
        initialize () {
            this.checkeds = this.fields.map(item => (this.modelValue[item.key] || {}).value || undefined)
            this.handleChangeNumberFormat(this.numberFormat)
        },
        isChecked (field) {
            return this.checkeds.includes(field)
        },
        indexChecked (field) {
            return this.checkeds.findIndex(f => f === field)
        },
        indexEmpty () {
            return this.checkeds.findIndex(f => f === undefined)
        },
        getSlotOfField (field) {
            const currentSlot = this.indexChecked(field)
            return currentSlot !== -1 ? currentSlot : this.indexEmpty()
        },
        getNumberFormats () {
            const { locationFormats } = Config.i18n
            return locationFormats.map(val => ({
                label: this.$t(`wizard.preview.numbersFormats.${val}`),
                value: val,
                description: `${this.$n(123456.05, 'decimal', val === 'eu' ? 'de-DE' : val)}`,
            }))
        },
        handeleRowMouseOver ({ col }, event) {
            this.hovered = col.field
        },
        handleRowMouseEnter ({ col }, event) {
            this.hovered = undefined
        },
        handleClickRow ({ col }) {
            const checked = this.isChecked(col.field)
            if (!this.disabledChecked) this.handleClickCol(col, !checked)
            else if (checked) this.handleClickCol(col, false)
        },
        handleClickCol (col, checked) {
            const { field, label } = col
            const slot = this.getSlotOfField(field)
            const fieldKey = (this.fields[slot] || {}).key
            const value = {
                fieldKey, field, value: field, label, slot, checked,
            }
            this.handleInput(value)
        },
        handleChangeNumberFormat ({ value: numberFormat }) {
            const { decimalCharacter, thousandCharacter } = this.App.getNumberFormatCharacters(numberFormat)
            this.decimalCharacter = decimalCharacter
            this.thousandCharacter = thousandCharacter
            sessionStorage.setItem('decimalCharacter', this.decimalCharacter)
            sessionStorage.setItem('thousandCharacter', this.thousandCharacter)
            this.$emit('update:modelValue', { ...this.modelValue, thousandCharacter, decimalCharacter })
        },
        handleChangeHomogenizeAttr () {
            const newValue = {
                ...(this.modelValue || {}),
                homogenizeAttr: this.homogenizeAttr,
            }
            this.$emit('update:modelValue', newValue)
        },
        handleInput ({
            fieldKey, field, value, label, slot, checked,
        } = {}) {
            const checkeds = [...this.checkeds.slice(0, slot), checked ? field : undefined, ...this.checkeds.slice(slot + 1)]
            const newValue = { ...(this.modelValue || {}), [fieldKey]: checked ? { label, value } : undefined }
            this.checkeds = checkeds
            this.notifyInput(newValue)

            return newValue
        },
        notifyInput (value) {
            this.$emit('update:modelValue', value)
        },
    },
}
</script>
<style scoped lang="scss">
.Preview{
    height:100%;
}
.Top {
    height:fit-content;
    margin-bottom: 10px;
}

.Options {
    width: 35%;
    margin-right: 5px;
}

.table-list {
    max-height: 51vh;
    @media screen and (min-height: $md) {
        max-height: 54vh;
    }
  padding-bottom:20px;
  &:deep() {
    flex: 1;
    color: $table-color !important;
    background: transparent !important;

    .q-table {
        tbody {
            td {
                padding: 15px;
                min-width: 130px;

                &:before {
                    background: transparent;
                }
            }
        }
    }
}

  .q-table__top,
  thead tr th {
    color: $table-head-color;
    font-size: $table-head-size;
    background: $table-head-bg;
}

  thead tr th {
    position: sticky;
    z-index: 1;
}
  thead tr:first-child th{
    top: 0;
  }
  thead tr:nth-child(2) th{
    top: 48px;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
}

  tbody{
    scroll-margin-top: 48px
}
}

.Header {
    &.disabled {
        opacity:1 !important;
    }
    &.hovered:not(.disabled) {
        cursor: pointer;

        &:deep(.q-checkbox__bg) {
            border-color: $white;
        }
    }
}

.FakeRow {
    background: $table-head-fake-bg;

    th {
        padding: 0 2px;
        border-left: 0;
        border-right: 0;
        background: $table-head-fake-bg !important;
    }
}

.Item {
    &.cursor {
        cursor: pointer
    }
    &.disabled {
        cursor: not-allowed;
    }
    &.hovered:not(.disabled) {
        background: rgba($primary, 0.05);
        cursor: pointer
    }
}

.Checkbox {
    &:deep(.q-checkbox__inner) {
        display: none;
    }
}

.Field {
    padding: 8px;
    border-radius: $border-radius;
    color: $white;
    background: $secondary;

    &.optional {
        background: $tertiary;
    }
}
.InputCharacter {
    width: 50px;
    margin-left: 10px;
}

.Select {
    overflow: none;
    margin-left: 10px;
}

.Warning {
    margin-top: 10px;
    line-height: 1.2;
    display: inline-block;
}

.WarningText {
    font-size: 12px;
    color: #dc9a00;
    font-weight:400;
    font-family: 'Roboto', 'sans-serif';
    text-align: left;
}

.WarningTextBold {
    font-size: 12px;
    color: #dc9a00;
    font-weight:700;
    font-family: 'Roboto', 'sans-serif';
    text-align: left;
}
 .Alert {
    color: #ffec54;
 }
</style>
