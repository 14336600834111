<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ $t('projects.models.modals.upload.title') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body q-pt-none q-px-lg">
                <QForm class="align-center q-gutter-md q-ma-md">
                    <QInput
                        v-model="modelName"
                        :label="$t('projects.models.modals.upload.name')"
                        outlined
                        stackLabel
                        dense
                        :rules="[val => !!val || 'Field is required']"
                    />
                    <QFile
                        v-model="file"
                        :label="$t('projects.models.modals.upload.file')"
                        dense
                        stackLabel
                        accept=".bpmn"
                        maxFileSize="1048576"
                        :rules="[val => !!val || 'Field is required']"
                        @rejected="onRejected"
                    >
                        <template #prepend>
                            <QIcon name="attach_file" />
                        </template>
                    </QFile>
                    <QBtn
                        :label="$t('projects.models.modals.upload.submit')"
                        noCaps
                        color="primary"
                        unelevated
                        @click="handleUploadModel"
                    />
                </QForm>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>

export default {
    name: 'Upload',
    components: {},
    emits: [
        'import', 'rejectedFile',
    ],
    data () {
        return {
            opened: false,
            modelName: undefined,
            file: undefined,
        }
    },
    methods: {
        open () {
            this.reset()
            this.opened = true
        },
        close () {
            this.opened = false
        },
        reset () {
            this.modelName = undefined
            this.file = undefined
        },
        handleUploadModel () {
            this.$emit('import', {
                modelName: this.modelName,
                file: this.file,
            })
        },
        onRejected () {
            this.$emit('rejectedFile')
        },
    },
}
</script>
<style scoped lang="scss">
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
.Header {
    padding-bottom: 0px;
}
</style>
