<template>
    <q-card>
        <q-card-section class="text-subtitle1 text-weight-bold text-primary">
            {{ $t('visualization.settings.relevantAttributes.name') }}
        </q-card-section>
        <q-card-section>
            <q-card
                bordered
                flat
                class="col-auto border border-grey2 rounded"
            >
                <q-card-section>
                    <div class="text-primary text-weight-bold q-my-sm">
                        {{ $t('visualization.settings.relevantAttributes.chooseRelevant') }}
                        ({{ relevantAttributes.length }} / {{ $t('visualization.settings.relevantAttributes.maxAttributes') }})
                    </div>
                    <q-select
                        v-model="relevantAttributes"
                        outlined
                        multiple
                        dense
                        hideSelected
                        labelColor="black"
                        color="primary"
                        :label="$t('visualization.settings.relevantAttributes.attributes')"
                        :maxValues="3"
                        :options="attributes"
                        @update:modelValue="updateRelevantAttributes"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleAttributesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </q-select>
                    <div class="q-my-sm">
                        <q-chip
                            v-for="(selected, index) in relevantAttributes"
                            :key="index"
                            removable
                            color="primary"
                            textColor="white"
                            @remove="deleteIndexValue(index)"
                        >
                            {{ selected }}
                        </q-chip>
                    </div>
                </q-card-section>
            </q-card>
        </q-card-section>
    </q-card>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'RelevantAttributesSettings',
    components: {},
    mixins: [],
    props: {
        attributes: VueTypes.array,
        values: VueTypes.array,
    },
    emits: ['UpdateAttributes'],
    data () {
        return {
            relevantAttributes: [],
            title: "$t('visualization.settings.cost.name')",
        }
    },
    mounted () {
        if (this.values) {
            this.relevantAttributes = [...this.values]
        }
    },
    methods: {
        updateRelevantAttributes () {
            this.$emit('UpdateAttributes', this.relevantAttributes)
        },
        deleteIndexValue (index) {
            this.relevantAttributes.splice(index, 1)
            this.updateRelevantAttributes()
        },
        handleAttributesInput (selected) {
            if (!this.relevantAttributes.includes(selected)) {
                if (this.relevantAttributes.length < 3) {
                    this.relevantAttributes.push(selected)
                }
            } else {
                const index = this.relevantAttributes.indexOf(selected)
                if (index !== -1) {
                    this.relevantAttributes.splice(index, 1)
                }
            }
            this.updateRelevantAttributes()
        },
    },
}
</script>
<style lang="scss" scoped>
.CaseCompletionSettings {
    min-height: 300px;
    max-width: 100%;
    top: $header-height;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;
    display: flex;
    padding: 10px;
    .CaseCompletion {
        width:100%;
        margin: 20px;
        display: flex;
        flex-direction: column;
        @media (max-height:$md) {
            margin: 10px;
        }
    }
    .title {
        margin-bottom: 10px;
    }
    .OptionsGroup {
        display: flex;
        gap: 10px;
        padding: 10px 0;
    }
    .Options {
        width: 60%;
    }
    .container {
        width:auto;
        padding: 25px;
        border: solid 1px rgba($black, 0.2);
        border-radius: 5px;
        margin-top: 10px;
        justify-content: center;
        @media (max-height:$md) {
            padding: 10px;
        }
    }

    .buttons {
        width: 80px;
    }
}
</style>
