<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ $t('projects.modals.upload.title') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body q-pt-none q-px-lg">
                <div class="Wrapper">
                    <div>{{ $t('projects.modals.upload.text') }}</div>
                    <QUploader
                        class="Uploader mt-1 mb-2"
                        flat
                        bordered
                        accept=".csv"
                        sendRaw
                        color="grey-1"
                        textColor="primary"
                        :autoUpload="true"
                        :headers="headers"
                        :method="method"
                        :url="url"
                        @finish="uploadFinished"
                        @start="uploadStarted"
                        @uploaded="uploadSuccess"
                    />
                </div>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import { getAuthorization } from '@/api'
import Config from '@/config'

export default {
    name: 'Upload',
    components: {},
    emits: ['upload-action'],
    data () {
        return {
            method: 'POST',
            uploaded: false,
            loading: false,
            uuid: '',
            opened: false,
        }
    },
    computed: {
        label () {
            return this.uploaded ? 'Close' : 'Cancel'
        },
        url () {
            const { baseURL } = Config.api
            return `${baseURL}/uploads/datasets/${this.uuid}`
        },
    },
    methods: {
        headers (files) {
            const { name } = files[0]

            return [
                { name: 'Authorization', value: getAuthorization() },
                { name: 'Content-Type', value: 'application/octet-stream' },
                { name: 'X-File-Name', value: name },
            ]
        },
        open (uuid) {
            this.opened = true
            this.uuid = uuid
        },
        close () {
            if (this.uploaded) this.$emit('upload-action')

            this.loading = false
            this.opened = false
            this.uploaded = false
        },
        uploadFinished () {
            this.loading = false
        },
        uploadStarted () {
            this.loading = true
        },
        uploadSuccess () {
            this.uploaded = true
        },
    },
}
</script>
<style scoped lang="scss">
.Card {
    .Wrapper {
        margin: 30px 0;
    }
}
.Uploader {
    width: 100%;
}
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
.Header {
    padding-bottom: 0px;
}
</style>
