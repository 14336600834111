<template>
    <div class="Container">
        <div class="TopContainer">
            <div class="ChartTitle">
                <strong style="color:#33699a;"> {{ $t('booster.prediction.predictedPerformance.duration') }}</strong>
            </div>
            <QSpace />
            <label style="margin:auto 3px;">
                {{ $t('booster.prediction.timeToMeet.past') }}
            </label>
            <QSelect
                v-model="durationMonths"
                dense
                outlined
                :options="months"
                @update:model-value="getData"
            />
            <label style="margin:auto 3px;">
                {{ $t('booster.prediction.timeToMeet.months') }}
            </label>
        </div>
        <div
            v-if="error"
            class="Error"
        >
            {{ error }}
        </div>
        <div
            v-else
            class="ChartContainer"
        >
            <q-inner-loading :showing="isLoading" />
            <v-chart
                v-if="!isLoading"
                ref="durationOptions"
                :option="durationOptions"
                class="Chart"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api, apiRequest, notifyError,
} from '@/api'

export default {
    name: 'DurationSla',
    props: {
        attribute: VueTypes.string,
        processId: VueTypes.string,
    },
    data () {
        return {
            durationMonths: 3,
            months: [3, 6, 9, 12],
            isLoading: false,
            error: undefined,
            durationOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        tooltipContent += `${(params[0].name)}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            tooltipContent += `${seriesName}: ${moment.duration(value, 'seconds').format('d[d] h[h]:m[m]:s[s]',
                                { largest: 2, trim: false })} <br>`
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                },
                grid: {
                    left: 25,
                    right: 25,
                    bottom: 50,
                    top: 15,
                },
                xAxis: {
                    show: false,
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                            color: '#000',
                            backgroundColor: '#fff',
                            borderRadius: 3,
                            formatter: this.$t('booster.prediction.predictedPerformance.pastPredicted'),
                        },
                    },
                    axisLabel: {
                        show: false,
                        // margin: 20,
                        // formatter (value) {
                        //     const maxLength = 12
                        //     if (value.length > maxLength) {
                        //         return `${value.substring(0, maxLength) }...`
                        //     // eslint-disable-next-line no-else-return
                        //     } else {
                        //         return value
                        //     }
                        // },
                    },
                },
                yAxis: {
                    show: false,
                },
                dataZoom: [
                    {
                        type: 'slider',
                        start: 0,
                        end: 100,
                        showDetail: false,
                        showDataShadow: false,
                        bottom: 10,
                        height: 15,
                    }],
                series: [
                    {
                        name: this.$t('booster.prediction.predictedPerformance.past'),
                        type: 'bar',
                        label: {
                            show: false,
                            position: 'bottom',
                            fontSize: 8.5,
                            formatter: this.$t('booster.prediction.predictedPerformance.past'),
                        },
                        itemStyle: {
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barGap: '30%',
                        silent: true,
                    },
                    {
                        name: this.$t('booster.prediction.predictedPerformance.predicted'),
                        type: 'bar',
                        label: {
                            show: false,
                            position: 'bottom',
                            fontSize: 8.5,
                            formatter: this.$t('booster.prediction.predictedPerformance.predicted'),
                        },
                        itemStyle: {
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barGap: '30%',
                        silent: true,
                    },
                ],
            },
        }
    },
    computed: {
    },
    watch: {
        attribute (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getData()
            }
        },
        durationOptions (newVal, oldVal) {
            if (newVal.series[0].itemStyle.color) {
                this.getData()
            }
        },
    },
    mounted () {
        this.getData()
        if (this.attribute) {
            this.getData()
            window.addEventListener('resize', this.handleResize)
        }
    },
    beforeUnmount  () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize () {
            if (this.$refs.durationOptions) {
                setTimeout(() => {
                    this.$refs.durationOptions.resize()
                }, 500)
            }
        },
        getData () {
            const params = { attribute: this.attribute, pastTime: this.durationMonths }
            this.resetOptions()
            this.isLoading = true
            apiRequest(Api().booster.predictedPerformanceDuration({ processId: this.processId, params }))
                .then((res) => {
                    res.rows.forEach((rowData) => {
                        this.durationOptions.xAxis.data.push(rowData.value)
                        this.durationOptions.series[0].data.push({
                            value: rowData.currentDuration,
                            itemStyle: {
                                color: rowData.currentMeetSLA ? '#7FFEA8' : '#ff8b90',
                                borderColor: rowData.currentMeetSLA ? '#04c48c' : '#fb2332',
                            },
                        })
                        this.durationOptions.series[1].data.push({
                            value: rowData.predictedDuration,
                            itemStyle: {
                                color: rowData.predictedMeetSLA ? '#7FFEA8' : '#ff8b90',
                                borderColor: rowData.predictedMeetSLA ? '#04c48c' : '#fb2332',
                            },
                        })
                    })
                })
                .catch((e) => {
                    if (e.result.response.data.errorCode === ('DE0076')) {
                        const translatedMonths = this.$t('booster.prediction.timeToMeet.months')
                        this.error = e.result.response.data.errorMessage.replace(
                            'pastTime',
                            `${this.durationMonths} ${translatedMonths}`,
                        )
                    } else {
                        notifyError()
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        resetOptions () {
            this.error = undefined
            this.durationOptions.xAxis.data = []
            this.durationOptions.series[1].data = []
            this.durationOptions.series[0].data = []
        },
    },
}
</script>
<style scoped lang="scss">
.Container {
    position: relative;
    display:flex;
    height: 100% !important;
    flex-direction:column;
    flex-wrap: wrap;
}
.TopContainer {
    display: flex;
    flex-direction: row;
    height: auto;
    background-color: #fbfbfb;
    padding: 1vh;
    height: fit-content;
}
.ChartTitle {
    display:flex;
    justify-content: center;
    align-items: center;
}
.Error {
    padding: 1vh;
    flex-grow: 1;
    background-color: #fbfbfb;
  }
.ChartContainer {
    width: 100%;
    flex-grow: 1;
    margin:0 auto;
    white-space: nowrap;
    .Chart {
        width: 100%;
        height: 90%;
        background-color: #fbfbfb;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }
}
.isLoading {
    display: flex;
    top: 35%;
    left: 75%;
    max-width: 50px;
    max-height: 50px;
}
</style>
