<template>
    <div class="contextComparison pr-1 pl-1 pt-1">
        <div class="buttonContainer">
            <QBtn
                class="button"
                color="primary"
                noCaps
                :label="$t('visualization.contextComparison.addContext')"
                icon="add_circle_outline"
                @click="addSection"
            />
            <QBtn
                class="button"
                color="primary"
                noCaps
                :label="$t('visualization.contextComparison.downloadCSV')"
                icon="download"
                @click="downloadCSV"
            />
        </div>
        <div class="cardsContainer">
            <div
                v-if="sections.length === 0"
                class="noDataContainer"
            >
                <div class="noData">
                    {{ $t('visualization.contextComparison.noData') }}
                </div>
                <div class="noDataExplanation">
                    {{ $t('visualization.contextComparison.noDataExplanation') }}
                </div>
            </div>
            <div
                v-for="(section, index) in sections"
                :key="section.sectionId"
                class="contextComparisonContainer q-card q-pa-md relative-position"
            >
                <div class="q-px-md row justify-between items-center">
                    <span class="text-weight-bold">
                        {{ `${$t('visualization.contextComparison.comparison')} ${index +1}` }}
                    </span>
                    <q-btn
                        flat
                        color="primary"
                        icon="close"
                        class="absolute-top-right"
                        @click="removeSection(section)"
                    />
                </div>
                <ContextComparisonCard
                    :filteredInfo="filteredInfo"
                    :sectionAssociated="section"
                    :processId="processId"
                    @filter-selected="saveFilterSelected"
                    @csv-position="CSVList"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersStorageMixin } from '@/mixins'
import { download } from '@/services/download'
import Notify from '@/services/notify'
import {
    notifySuccess,
} from '@/api'
import ContextComparisonCard from './ContextComparisonCard.vue'

export default {
    name: 'ContextComparison',
    components: {
        ContextComparisonCard,
    },
    mixins: [filtersStorageMixin],
    props: {
        filteredInfo: VueTypes.oneOfType([VueTypes.object, VueTypes.array]),
        sections: VueTypes.array,
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    },
    emits: ['sectionsList'],
    data () {
        return {
            filtersSaved: null,
            csvList: [],
            localSections: this.sections,
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
        addSection () {
            if (this.localSections.length <= 7) {
                const nextId = this.checkIdAvailable()
                const newSection = {
                    sectionId: nextId,
                }
                this.localSections.push(newSection)
                this.$emit('sectionsList', this.localSections)
            } else {
                const notify = ({ message, type = 'negative' }) => Notify.create({
                    message,
                    color: type,
                })
                const mes = this.$t('visualization.contextComparison.maxContext')
                notify({ message: mes })
            }
        },
        downloadCSV () {
            const csvContent = this.csvList.reduce((acc, obj) => {
                if (acc === `${Object.keys(this.csvList[0].data).join(',')}\n`) {
                    const headers = Object.keys(this.csvList[0].data).map((header) => {
                        if (header === 'endDateDifference' || header === 'startDateDifference') {
                            return `${header} ${this.$t('visualization.contextComparison.seconds')}`
                        }
                        return header
                    }).join(',')
                    // eslint-disable-next-line no-param-reassign
                    acc = `${headers}\n`
                }
                const row = Object.values(obj.data).join(',')
                return `${acc}${row}\n`
            }, `${Object.keys(this.csvList[0].data).join(',') }\n`)
            download({ name: this.$t('visualization.tabs.contextComparison'), data: csvContent })
            notifySuccess(this.$t('visualization.traces.downloadOK'), (this.isLoading = false))
        },
        CSVList (data) {
            const existingIndex = this.csvList.findIndex(item => item.id === data.id)
            if (existingIndex !== -1) {
                this.csvList[existingIndex] = data // Direct assignment
            } else {
                this.csvList.push(data)
            }
        },
        async removeSection (section) {
            const index = this.localSections.indexOf(section)
            this.localSections.splice(index, 1)
            this.csvList.splice(index, 1)
            this.$emit('sectionsList', this.localSections)
        },
        saveFilterSelected (dataset) {
            for (let i = 0; i < this.localSections.length; i += 1) {
                if (this.localSections[i].sectionId === dataset.section.sectionId) {
                    this.localSections[i].dataset = dataset.dataset
                    this.localSections[i].filter = dataset.filter
                    this.localSections[i].metricDuration = dataset.metricDuration
                }
            }
            this.$emit('sectionsList', this.localSections)
        },
        checkIdAvailable () {
            const biggestId = this.localSections.reduce((s, obj) => Math.max(s, obj.sectionId), 0)
            return biggestId + 1
        },
    },
}
</script>
<style scoped lang="scss">
.closeContainer {
    display: inline-block;
}
.button{
    margin:0px 10px 10px 20px;
}
.contextComparisonContainer{
    margin: 20px;
    width: 22%;
    min-width: 368px;
    display: inline-block;
}
.closeContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
}
.noDataContainer{
    display: flex;
    margin: 10em 0em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.noData{
    font-weight: bold;
    font-size: 2em;
}
.noDataExplanation{
    font-size: 1.4em;
}
.buttonContainer{
    width: 100%;
}
.cardsContainer{
   display: flex;
   flex-wrap: wrap;
}

</style>
