<template>
    <div class="Container">
        <div class="TopContainer">
            <div class="ChartTitle">
                <strong style="color:#33699a;"> {{ $t('booster.prediction.predictedPerformance.distribution') }}</strong>
            </div>
            <QSpace />
            <label style="margin:auto 3px;">
                {{ $t('booster.prediction.timeToMeet.past') }}
            </label>
            <QSelect
                v-model="durationMonths"
                dense
                outlined
                :options="months"
                @update:model-value="getData"
            />
            <label style="margin:auto 3px;">
                {{ $t('booster.prediction.timeToMeet.months') }}
            </label>
            <QBtnToggle
                v-model="optionSel.value"
                toggleColor="primary"
                style="margin:auto 10px auto 5%;"
                noCaps
                dense
                :options="options"
                @update:model-value="checkOptionSel"
            />
        </div>
        <div
            v-if="error"
            class="Error"
        >
            {{ error }}
        </div>
        <div
            v-else
            class="ChartContainer"
        >
            <q-inner-loading :showing="isLoading" />
            <v-chart
                v-if="!isLoading"
                ref="perfDistribution"
                :option="perfDistributionOptions"
                class="Chart"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError,
} from '@/api'

export default {
    name: 'DistributionSla',
    props: {
        attribute: VueTypes.string,
        processId: VueTypes.string,
    },
    data () {
        return {
            optionSel: {
                value: 'SLACOMPLIANCEFREQ',
                label: 'abs',
            },
            options: [
                {
                    value: 'SLACOMPLIANCEFREQ',
                    label: 'abs',
                },
                {
                    value: 'SLACOMPLIANCEPERCENTAGE',
                    label: '%',
                },
            ],
            durationMonths: 3,
            months: [3, 6, 9, 12],
            isLoading: false,
            error: undefined,
            response: undefined,
            perfDistributionOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        tooltipContent += `${(params[0].name)}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            this.optionSel.value === 'SLACOMPLIANCEFREQ'
                                ? tooltipContent += `${seriesName}:  ${value} ${this.$t('booster.state.cases')}`
                                : tooltipContent += `${seriesName}: ${parseFloat((value).toFixed(2))} %`
                            tooltipContent += '<br></div>'
                        })
                        return tooltipContent
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        start: 0,
                        end: 100,
                        showDetail: false,
                        showDataShadow: false,
                        bottom: 10,
                        height: 15,
                    }],
                grid: {
                    left: 25,
                    right: 25,
                    bottom: 50,
                    top: 15,
                },
                xAxis: {
                    show: false,
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                            color: '#000',
                            backgroundColor: '#fff',
                            borderRadius: 3,
                            formatter: this.$t('booster.prediction.predictedPerformance.finishedOngoing'),
                        },
                    },
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                        // margin: 20,
                        // formatter (value) {
                        //     const maxLength = 12
                        //     if (value.length > maxLength) {
                        //         return `${value.substring(0, maxLength)}...`
                        //     // eslint-disable-next-line no-else-return
                        //     } else {
                        //         return value
                        //     }
                        // },
                    },
                },
                yAxis: {
                    show: false,
                },
                series: [
                    {
                        name: this.$t('booster.prediction.predictedPerformance.finishedUnder'),
                        type: 'bar',
                        stack: 'finished',
                        label: {
                            show: false,
                            position: 'bottom',
                            fontSize: 8.5,
                            formatter: this.$t('booster.prediction.predictedPerformance.finishedUnder'),
                        },
                        itemStyle: {
                            borderColor: '#00C383',
                            color: '#7FFEA8',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barGap: '30%',
                    },
                    {
                        name: this.$t('booster.prediction.predictedPerformance.finishedAbove'),
                        type: 'bar',
                        stack: 'finished',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#F40019',
                            color: '#FF8084',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barGap: '30%',
                    },
                    {
                        name: this.$t('booster.prediction.predictedPerformance.ongoingUnder'),
                        type: 'bar',
                        stack: 'ongoing',
                        label: {
                            show: false,
                            position: 'bottom',
                            fontSize: 8.5,
                            formatter: 'prueba',
                        },
                        itemStyle: {
                            borderColor: '#00C383',
                            color: '#7FFEA8',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barGap: '30%',
                    },
                    {
                        name: this.$t('booster.prediction.predictedPerformance.ongoingAbove'),
                        type: 'bar',
                        stack: 'ongoing',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#F40019',
                            color: '#FF8084',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barGap: '30%',
                    },
                ],
            },
        }
    },
    watch: {
        attribute (value) {
            if (value) {
                this.getData()
            }
        },
    },
    mounted () {
        if (this.attribute) {
            this.getData()
        }
        if (this.attribute) {
            this.getData()
            window.addEventListener('resize', this.handleResize)
        }
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize () {
            if (this.$refs.perfDistribution) {
                setTimeout(() => {
                    this.$refs.perfDistribution.resize()
                }, 500)
            }
        },
        getData () {
            const params = { attribute: this.attribute, pastTime: this.durationMonths }
            this.isLoading = true
            this.resetOptions()
            apiRequest(Api().booster.predictedPerformanceDistribution({ processId: this.processId, params }))
                .then((res) => {
                    this.response = res
                    this.checkOptionSel()
                })
                .catch((e) => {
                    if (e.result.response.data.errorCode === ('DE0076')) {
                        const translatedMonths = this.$t('booster.prediction.timeToMeet.months')
                        this.error = e.result.response.data.errorMessage.replace(
                            'pastTime',
                            `${this.durationMonths} ${translatedMonths}`,
                        )
                    } else {
                        notifyError()
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        resetOptions () {
            this.error = undefined
            this.perfDistributionOptions.yAxis.max = undefined
            this.perfDistributionOptions.series[0].data = undefined
            this.perfDistributionOptions.series[1].data = undefined
            this.perfDistributionOptions.series[2].data = undefined
            this.perfDistributionOptions.series[3].data = undefined
        },
        checkOptionSel () {
            if (!this.error) {
                this.perfDistributionOptions.xAxis.data = this.response.rows.map(i => i.value)
                if (this.optionSel.value === 'SLACOMPLIANCEFREQ') {
                    this.perfDistributionOptions.yAxis.max = undefined
                    this.perfDistributionOptions.series[0].data = this.response.rows.map(i => i.finishedUnderSlaAbsolute)
                    this.perfDistributionOptions.series[1].data = this.response.rows.map(i => i.finishedAboveSlaAbsolute)
                    this.perfDistributionOptions.series[2].data = this.response.rows.map(i => i.ongoingUnderSlaAbsolute)
                    this.perfDistributionOptions.series[3].data = this.response.rows.map(i => i.ongoingAboveSlaAbsolute)
                } else {
                    this.perfDistributionOptions.yAxis.max = 100
                    this.perfDistributionOptions.series[0].data = this.response.rows.map(i => i.finishedUnderSlaPercentage * 100)
                    this.perfDistributionOptions.series[1].data = this.response.rows.map(i => i.finishedAboveSlaPercentage * 100)
                    this.perfDistributionOptions.series[2].data = this.response.rows.map(i => i.ongoingUnderSlaPercentage * 100)
                    this.perfDistributionOptions.series[3].data = this.response.rows.map(i => i.ongoingAboveSlaPercentage * 100)
                }
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Container {
    position: relative;
    display:flex;
    height: 100% !important;
    max-width: 100% !important;
    flex-direction:column;
    flex-wrap: wrap;
}
.TopContainer {
    display: flex;
    flex-direction: row;
    height: auto;
    padding: 1vh;
    height: fit-content;
    background-color: #fbfbfb;
}
.Error {
    padding: 1vh;
    flex-grow: 1;
    background-color: #fbfbfb;
  }
.ChartTitle {
    display:flex;
    justify-content: center;
    align-items: center;
}
.ChartContainer {
    width: 100%;
    flex-grow: 1;
    margin:0 auto;
    white-space: nowrap;
    .Chart {
        width: 100%;
        height: 90%;
        background-color: #fbfbfb;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }
}
.isLoading {
    display: flex;
    top: 35%;
    left: 75%;
    max-width: 50px;
    max-height: 50px;
}
</style>
