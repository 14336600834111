const allowedChars = (value) => {
    const regex = /^[a-zA-Z0-9À-ÿ_\-\s]*$/
    return regex.test(value)
}

const validEmail = (value) => {
    const regex = /\b[\w\\.-]+@[\w\\.-]+\.\w{2,4}\b/
    return regex.test(value)
}

const required = value => !!value
const optional = value => !value

const maxLength = (value, num = 0) => (value || '').length <= num
const minLength = (value, num = 0) => (value || '').length >= num
const betweenLength = (value, min, max) => value && (value || '').length >= min && (value || '').length <= max

const minValue = (value, min) => value >= min
const maxValue = (value, max) => value <= max

export default {
    required,
    optional,
    allowedChars,
    maxLength,
    minLength,
    betweenLength,
    validEmail,
    minValue,
    maxValue,
}
