<template>
    <div>
        <div>
            <div> {{ $t('booster.alerts.definition.createScreen.triggerConditions.label') }} </div>
            <div
                class="q-gutter-md items-center"
            >
                <div>
                    <div
                        v-if="!configure"
                        class="FormContainer"
                    >
                        <div :class="selectedFilter && selectedFilter.id === 'ACTIVITIES_DURATION' ? 'column' : 'row'">
                            <div
                                class="row flex"
                            >
                                <QSelect
                                    ref="filters"
                                    v-model="selectedFilter"
                                    :options="filters"
                                    :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectFilter')"
                                    stackLabel
                                    dense
                                    behavior="menu"
                                    outlined
                                    :rules="[validateRequired]"
                                    @update:modelValue="resetSelectors"
                                />
                                <QSelect
                                    v-if="selectedFilter && (
                                        (selectedFilter.kind === 'ACTIVITIES') ||
                                        (selectedFilter.id === 'ACTIVITY_DURATION'))"
                                    ref="activities"
                                    v-model="selectedActivity"
                                    :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectActivities')"
                                    :options="activities"
                                    stackLabel
                                    dense
                                    outlined
                                    :rules="[value => validators.required(value)]"
                                    :multiple="(
                                        selectedFilter.id === 'TRACE_STARTS_WITH_ACTIVITY' ||
                                        selectedFilter.id === 'TRACE_ENDS_WITH_ACTIVITY' ||
                                        (selectedFilter.id === 'ACTIVITY_DURATION')) ? false : true"
                                >
                                    <template
                                        v-if="selectedFilter.id === 'TRACE_CONTAINS_ACTIVITIES' || selectedFilter.id === 'TRACE_NOT_CONTAINS_ACTIVITIES'"
                                        #option="{ itemProps, opt, selected, toggleOption }"
                                    >
                                        <QItem
                                            v-bind="itemProps"
                                            style="pointer-events: none;"
                                        >
                                            <QItemSection>
                                                <QItemLabel v-html="opt" />
                                            </QItemSection>
                                            <QItemSection side>
                                                <QToggle
                                                    :modelValue="selected"
                                                    :val="opt"
                                                    style="pointer-events: auto;"
                                                    @update:modelValue="toggleOption(opt)"
                                                />
                                            </QItemSection>
                                        </QItem>
                                    </template>
                                </QSelect>
                                <QSelect
                                    v-if="selectedFilter && selectedFilter.kind === 'ATTRIBUTE'"
                                    ref="activities"
                                    v-model="selectedActivity"
                                    :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectActivities')"
                                    :options="activitiesOptions"
                                    stackLabel
                                    dense
                                    outlined
                                    :rules="[value => validators.required(value)]"
                                />
                                <QSelect
                                    v-if="selectedFilter && ((selectedFilter.kind === 'ARCS') || (selectedFilter.id === 'ARC_DURATION'))"
                                    ref="arcs"
                                    v-model="selectedArc"
                                    :multiple="(
                                        selectedFilter.id === 'ARC_DURATION') ? false : true"
                                    :options="arcs"
                                    :rules="[value => validators.required(value)]"
                                    :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectArcs')"
                                    dense
                                    outlined
                                    stackLabel
                                >
                                    <template
                                        v-if="(selectedFilter.kind === 'ARCS')"
                                        #option="{ itemProps, opt, selected, toggleOption }"
                                    >
                                        <QItem
                                            v-bind="itemProps"
                                            style="pointer-events: none;"
                                        >
                                            <QItemSection>
                                                <QItemLabel v-html="opt" />
                                            </QItemSection>
                                            <QItemSection side>
                                                <QToggle
                                                    :modelValue="selected"
                                                    :val="opt"
                                                    style="pointer-events: auto;"
                                                    @update:modelValue="toggleOption(opt)"
                                                />
                                            </QItemSection>
                                        </QItem>
                                    </template>
                                </QSelect>
                                <div
                                    v-if="selectedFilter && selectedFilter.kind === 'ATTRIBUTE'"
                                    class="row flex"
                                >
                                    <QSelect
                                        ref="attributes"
                                        v-model="selectedAttribute"
                                        dense
                                        outlined
                                        hideBottomSpace
                                        stackLabel
                                        behavior="menu"
                                        :rules="[value => validators.required(value)]"
                                        :options="attributesOptions"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectAttribute')"
                                    >
                                        <QTooltip v-if="!attributesOptions">
                                            {{ $t('booster.alerts.definition.createScreen.triggerConditions.noAttributes') }}
                                        </QTooltip>
                                    </QSelect>
                                    <QSelect
                                        ref="attributeConstraints"
                                        v-model="attributeConstraints"
                                        dense
                                        outlined
                                        stackLabel
                                        :rules="[value => validators.required(value)]"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.constraints')"
                                        :options="ATTRIBUTTE_TYPES[selectedFilter.id]"
                                    />
                                    <div
                                        v-if="selectedFilter.id === 'NUMERIC_ATTRIBUTE'"
                                        class="MultipleFilterContainer"
                                        :class="{ 'TwoInputs': attributeConstraints?.value === 'TRACE_ATTRIBUTES_NUM_BETWEEN' }"
                                    >
                                        <QInput
                                            ref="numericAttribute"
                                            v-model="attrValue"
                                            class="Field"
                                            :class="{ 'FullWidth': attributeConstraints?.value !== 'TRACE_ATTRIBUTES_NUM_BETWEEN' }"
                                            stackLabel
                                            :rules="[value => value >= 0 || $t('booster.alerts.definition.createScreen.triggerConditions.minValue')]"
                                            :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectValue')"
                                            type="number"
                                            :min="0"
                                            dense
                                            outlined
                                        />
                                        <QInput
                                            v-if="attributeConstraints?.value === 'TRACE_ATTRIBUTES_NUM_BETWEEN'"
                                            ref="numericAttribute"
                                            v-model="attrValue2"
                                            class="Field"
                                            stackLabel
                                            :rules="[value => value >= 0 || $t('booster.alerts.definition.createScreen.triggerConditions.minValue')]"
                                            :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectValue')"
                                            type="number"
                                            :min="0"
                                            dense
                                            outlined
                                        />
                                    </div>
                                    <QSelect
                                        v-if="selectedFilter.id === 'CATEGORICAL_ATTRIBUTE'"
                                        ref="categoricalAttribute"
                                        v-model="attrValue"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectValue')"
                                        dense
                                        stackLabel
                                        outlined
                                        :rules="[value => validators.required(value)]"
                                        :options="(attrValues?.find(item => item.name === selectedAttribute)?.values)"
                                    />
                                    <div
                                        v-if="selectedFilter.id === 'DATETIME_ATTRIBUTE'"
                                        class="MultipleFilterContainer"
                                        :class="{ 'TwoInputs': attributeConstraints?.value === 'TRACE_ATTRIBUTES_DATE_BETWEEN' }"
                                    >
                                        <QInput
                                            ref="dateAttribute"
                                            v-model="attrValue"
                                            class="Field"
                                            :class="{ 'FullWidth': attributeConstraints?.value !== 'TRACE_ATTRIBUTES_DATE_BETWEEN' }"
                                            outlined
                                            dense
                                            :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectDate')"
                                            type="date"
                                        />
                                        <QInput
                                            v-if="attributeConstraints?.value === 'TRACE_ATTRIBUTES_DATE_BETWEEN'"
                                            ref="dateAttribute"
                                            v-model="attrValue2"
                                            class="Field"
                                            outlined
                                            dense
                                            :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectDate')"
                                            type="date"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-if="selectedFilter && selectedFilter.kind === 'DATETIME'"
                                    class="row flex"
                                >
                                    <QInput
                                        ref="time"
                                        v-model="selectedTime"
                                        dense
                                        outlined
                                        type="time"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectTime')"
                                    />
                                    <QInput
                                        ref="date"
                                        v-model="selectedDate"
                                        dense
                                        outlined
                                        type="date"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectDate')"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="selectedFilter && selectedFilter.id === 'ACTIVITIES_DURATION'"
                                class="column flex"
                            >
                                <div class="row">
                                    <QRadio
                                        v-model="radioFilter"
                                        val="actOccurrence"
                                        label="By activity occurrence order"
                                    />
                                    <QRadio
                                        v-model="radioFilter"
                                        val="pairRepetition"
                                        label="By pair repetition"
                                    />
                                </div>
                                <div class="row">
                                    <QSelect
                                        v-if="radioFilter==='actOccurrence'"
                                        ref="firstActivityConstraint"
                                        v-model="firstActivityConstraint"
                                        :options="ATTRIBUTTE_TYPES.OCCURRENCE_ATTRIBUTE"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.occurrenceOrder')"
                                        stackLabel
                                        :rules="[value => validators.required(value)]"
                                        dense
                                        outlined
                                    />
                                    <QSelect
                                        ref="firstActivity"
                                        v-model="firstActivity"
                                        :options="activities"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectActivity')"
                                        stackLabel
                                        :rules="[value => validators.required(value)]"
                                        dense
                                        outlined
                                    />
                                </div>
                                <div class="row">
                                    <QSelect
                                        v-if="radioFilter==='actOccurrence'"
                                        ref="secondActivityConstraint"
                                        v-model="secondActivityConstraint"
                                        :options="ATTRIBUTTE_TYPES.OCCURRENCE_ATTRIBUTE"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.occurrenceOrder')"
                                        stackLabel
                                        :rules="[value => validators.required(value)]"
                                        dense
                                        outlined
                                    />
                                    <QSelect
                                        ref="secondActivity"
                                        v-model="secondActivity"
                                        :options="activities"
                                        :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectActivity')"
                                        stackLabel
                                        :rules="[value => validators.required(value)]"
                                        dense
                                        outlined
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <QSelect
                                    v-if="selectedFilter && selectedFilter.kind === 'DURATION' && selectedFilter.id !== 'ACTIVITIES_DURATION'"
                                    ref="durationConstraints"
                                    v-model="durationConstraints"
                                    dense
                                    outlined
                                    stackLabel
                                    behavior="menu"
                                    :rules="[value => validators.required(value)]"
                                    :label="$t('booster.alerts.definition.createScreen.triggerConditions.constraints')"
                                    :options="DURATION_FILTERS.find(filter => filter.id === selectedFilter.id)?.options"
                                />
                                <QSelect
                                    v-if="selectedFilter && selectedFilter.kind === 'DURATION' && selectedFilter.id === 'ACTIVITIES_DURATION'"
                                    ref="durationConstraints"
                                    v-model="durationConstraints"
                                    dense
                                    outlined
                                    stackLabel
                                    behavior="menu"
                                    :rules="[value => validators.required(value)]"
                                    :label="$t('booster.alerts.definition.createScreen.triggerConditions.constraints')"
                                    :options="radioFilter==='actOccurrence' ?
                                        DURATION_FILTERS.find(filter => filter.id === selectedFilter?.id)?.options :
                                        DURATION_FILTERS.find(filter => filter.id === selectedFilter?.id)?.loopOptions"
                                />
                                <QInput
                                    v-if="selectedFilter && selectedFilter.kind === 'DURATION'"
                                    ref="durationValue"
                                    v-model="selectedDurationValue"
                                    :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectValue')"
                                    type="number"
                                    :min="1"
                                    :rules="[value => value >= 1 || $t('booster.alerts.definition.createScreen.triggerConditions.minValue')]"
                                    stackLabel
                                    dense
                                    outlined
                                />
                                <QSelect
                                    v-if="selectedFilter && selectedFilter.kind === 'DURATION'"
                                    ref="durationUnit"
                                    v-model="selectedDurationUnit"
                                    dense
                                    outlined
                                    stackLabel
                                    :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectUnit')"
                                    :options="timeUnits"
                                />
                                <QIcon
                                    name="add_circle_outline"
                                    color="primary"
                                    flat
                                    class="AddIconStyle"
                                    dense
                                    size="sm"
                                    type="submit"
                                    @click="addSelection"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="q-gutter-sm q-mt-md">
                        <QChip
                            v-for="(selection, index) in configure ? configureTriggers : triggers"
                            :key="index"
                            :label="formatSelection(selection)"
                            removable
                            :disable="configure"
                            class="bg-primary text-white Chip"
                            @remove="removeSelection(index)"
                        >
                            <QTooltip>
                                {{ formatSelection(selection) }}
                            </QTooltip>
                        </QChip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import { validatorsMixin, filtersStorageMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import {
    ATTRIBUTTE_TYPES,
} from '../../../Visualization/sections/FilterModal/filtersEntity'

export default {
    name: 'AlertTriggers',
    mixins: [validatorsMixin, filtersStorageMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        configure: VueTypes.bool.def(false),
        configureTriggers: VueTypes.object,
        noTriggers: VueTypes.bool,
    },
    emits: ['update:triggers'],
    data () {
        return {
            unit: {
                value: 'M',
                label: this.$t('booster.alerts.definition.timeUnit.minutes'),
            },
            activities: [],
            attributes: [],
            categoricalAttributes: [],
            categoricalAttributesWithValues: undefined,
            filters: [],
            allFilters: [],
            selectedFilter: undefined,
            selectedActivity: undefined,
            selectedArc: undefined,
            selectedAttribute: undefined,
            selectedTime: null,
            selectedDate: null,
            selectedDurationValue: undefined,
            selectedDurationUnit: undefined,
            durationConstraints: undefined,
            radioFilter: 'actOccurrence',
            firstActivity: undefined,
            firstActivityConstraint: undefined,
            secondActivity: undefined,
            secondActivityConstraint: undefined,
            attributeConstraints: [],
            attrValues: [],
            attrValue: '',
            attrValue2: '',
            triggers: [],
        }
    },
    computed: {
        activitiesOptions () {
            return [{ label: this.$t('visualization.filters.attributes.categoryAny'), value: '*' },
                ...this.activities.map(activity => ({ label: activity, value: activity })),
            ]
        },
        timeUnits () {
            return [{
                value: 's',
                label: this.$t('booster.alerts.definition.timeUnit.seconds'),
            },
            {
                value: 'm',
                label: this.$t('booster.alerts.definition.timeUnit.minutes'),
            },
            {
                value: 'h',
                label: this.$t('booster.alerts.definition.timeUnit.hours'),
            },
            {
                value: 'd',
                label: this.$t('booster.alerts.definition.timeUnit.days'),
            },
            // Preguntar por estas categorías, no estaban contempladas en los filtros normales, pero sí en el mockup
            // {
            //     value: 'w',
            //     label: this.$t('booster.alerts.definition.timeUnit.weeks'),
            // },
            // {
            //     value: 'M',
            //     label: this.$t('booster.alerts.definition.timeUnit.months'),
            // },
            ]
        },
        attributesOptions () {
            if (this.selectedFilter.id === 'NUMERIC_ATTRIBUTE') {
                return this.filterAttributesByType(this.attributes, ['SHORT', 'DECIMAL'])
            }
            if (this.selectedFilter.id === 'BOOLEAN_ATTRIBUTE') {
                return this.filterAttributesByType(this.attributes, 'BOOLEAN')
            }
            if (this.selectedFilter.id === 'CATEGORICAL_ATTRIBUTE') {
                return this.filterAttributesByType(this.categoricalAttributesWithValues, 'STRING')
            }
            if (this.selectedFilter.id === 'DATETIME_ATTRIBUTE') {
                return this.filterAttributesByType(this.attributes, 'TIMESTAMP')
            }
            return this.attributes
        },
    },
    watch: {
        noTriggers (newVal) {
            if (newVal) {
                this.$refs.filters.validate()
            }
        },
    },
    beforeMount () {
        this.DURATION_FILTERS = [
            {
                label: this.$t('visualization.filters.duration.filters.trace'),
                kind: 'DURATION',
                id: 'TRACE_DURATION',
                options: [
                    { label: this.$t('visualization.filters.attributes.filters.greaterThan'), id: 'TRACE_DURATION_GREATER_THAN' },
                    { label: this.$t('visualization.filters.attributes.filters.lesserThan'), id: 'TRACE_DURATION_LESS_THAN' },
                ],
            },
            {
                label: this.$t('visualization.filters.duration.filters.activity'),
                kind: 'DURATION',
                id: 'ACTIVITY_DURATION',
                options: [
                    { label: this.$t('visualization.filters.attributes.filters.greaterThan'), id: 'ACTIVITY_DURATION_GREATER_THAN' },
                    { label: this.$t('visualization.filters.attributes.filters.lesserThan'), id: 'ACTIVITY_DURATION_LESS_THAN' },
                ],
            },
            {
                label: this.$t('visualization.filters.duration.filters.activities'),
                kind: 'DURATION',
                id: 'ACTIVITIES_DURATION',
                options: [
                    { label: this.$t('visualization.filters.attributes.filters.greaterThan'), id: 'ACTIVITIES_DURATION_GREATER_THAN' },
                    { label: this.$t('visualization.filters.attributes.filters.lesserThan'), id: 'ACTIVITIES_DURATION_LESS_THAN' },
                ],
                loopOptions: [
                    { label: this.$t('visualization.filters.attributes.filters.greaterThan'), id: 'ACTIVITIES_DURATION_LOOP_GREATER_THAN' },
                    { label: this.$t('visualization.filters.attributes.filters.lesserThan'), id: 'ACTIVITIES_DURATION_LOOP_LESS_THAN' },
                ],
            },
            {
                label: this.$t('visualization.filters.duration.filters.arc'),
                kind: 'DURATION',
                id: 'ARC_DURATION',
                options: [
                    { label: this.$t('visualization.filters.attributes.filters.greaterThan'), id: 'ARC_DURATION_GREATER_THAN' },
                    { label: this.$t('visualization.filters.attributes.filters.lesserThan'), id: 'ARC_DURATION_LESSER_THAN' },
                ],
            },
        ]
        this.ATTR_FILTERS = [
            {
                label: this.$t('visualization.filters.attributes.filters.numericalField'), kind: 'ATTRIBUTE', id: 'NUMERIC_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.ctgField'), kind: 'ATTRIBUTE', id: 'CATEGORICAL_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.booleanField'), kind: 'ATTRIBUTE', id: 'BOOLEAN_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.dateTimeField'), kind: 'ATTRIBUTE', id: 'DATETIME_ATTRIBUTE',
            },
        ]
        this.ATTRIBUTTE_TYPES = {
            CATEGORICAL_ATTRIBUTE: ATTRIBUTTE_TYPES.CATEGORICAL_ATTRIBUTE(),
            BOOLEAN_ATTRIBUTE: ATTRIBUTTE_TYPES.BOOLEAN_ATTRIBUTE(),
            NUMERIC_ATTRIBUTE: ATTRIBUTTE_TYPES.NUMERIC_ATTRIBUTE(),
            DATETIME_ATTRIBUTE: ATTRIBUTTE_TYPES.DATETIME_ATTRIBUTE(),
            OCCURRENCE_ATTRIBUTE: ATTRIBUTTE_TYPES.OCCURRENCE_ATTRIBUTE(),
        }
    },
    mounted () {
        this.getFilters()
        this.getAttributeValues()
    },
    methods: {
        addSelection () {
            let selFilter = {}
            if (this.selectedFilter) {
                if (this.selectedFilter.kind === 'ATTRIBUTE') {
                    let formattedAttrValue
                    if (this.selectedFilter.id === 'NUMERIC_ATTRIBUTE' && this.attributeConstraints.value === 'TRACE_ATTRIBUTES_NUM_BETWEEN') {
                        formattedAttrValue = [this.attrValue, this.attrValue2]
                    } else if (this.selectedFilter.id === 'DATETIME_ATTRIBUTE' && this.attributeConstraints.value === 'TRACE_ATTRIBUTES_DATE_BETWEEN') {
                        formattedAttrValue = [moment(this.attrValue).format('YYYY-MM-DDTHH:mm:ss'), moment(this.attrValue2).format('YYYY-MM-DDTHH:mm:ss')]
                    } else if (this.selectedFilter.id === 'DATETIME_ATTRIBUTE') {
                        formattedAttrValue = [moment(this.attrValue).format('YYYY-MM-DDTHH:mm:ss')]
                    } else {
                        formattedAttrValue = [this.attrValue]
                    }
                    selFilter = {
                        id: this.attributeConstraints?.value,
                        ...(this.selectedActivity.value !== '*' && { activity: this.selectedActivity.value }),
                        attribute: this.selectedAttribute,
                        ...(this.selectedFilter.id !== 'BOOLEAN_ATTRIBUTE' && { values: formattedAttrValue }),
                    }
                }
                if ((this.selectedFilter.kind === 'ACTIVITIES') || (this.selectedFilter.kind === 'ARCS')) {
                    selFilter = {
                        id: this.selectedFilter.id,
                        // eslint-disable-next-line no-nested-ternary
                        values: Array.isArray(this.selectedFilter.kind === 'ACTIVITIES' ? this.selectedActivity : this.selectedArc)
                            ? (this.selectedFilter.kind === 'ACTIVITIES' ? this.selectedActivity : this.selectedArc)
                            : [this.selectedFilter.kind === 'ACTIVITIES' ? this.selectedActivity : this.selectedArc],
                    }
                }
                if (this.selectedFilter.kind === 'DATETIME') {
                    selFilter = {
                        id: this.selectedFilter.id,
                        values: (this.selectedDate && this.selectedTime)
                            ? [moment(`${this.selectedDate} ${this.selectedTime}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DDTHH:mm:ss")]
                            : undefined,
                    }
                }
                if (this.selectedFilter.kind === 'DURATION') {
                    const baseFilter = {
                        id: this.durationConstraints?.id,
                        values: [moment.duration(this.selectedDurationValue, this.selectedDurationUnit?.value).toISOString()],
                    }

                    if (this.selectedFilter.id === 'TRACE_DURATION') {
                        selFilter = baseFilter
                    } else if (this.selectedFilter.id === 'ACTIVITY_DURATION') {
                        selFilter = { ...baseFilter, activity: this.selectedActivity }
                    } else if (this.selectedFilter.id === 'ARC_DURATION') {
                        selFilter = { ...baseFilter, arc: this.selectedArc }
                    } else {
                        const baseValues = [
                            this.firstActivity,
                            this.secondActivity,
                            moment.duration(this.selectedDurationValue, this.selectedDurationUnit?.value).toISOString(),
                        ]
                        selFilter = {
                            id: this.durationConstraints?.id,
                            values: this.radioFilter === 'actOccurrence'
                                ? [this.firstActivity, this.firstActivityConstraint?.value, this.secondActivity, this.secondActivityConstraint?.value, baseValues[2]]
                                : baseValues,
                        }
                    }
                }
            }
            if (!this.hasEmptyFields(selFilter)) {
                this.triggers.push(selFilter)
                this.$emit('update:triggers', this.triggers)
                this.resetSelectors()
                this.selectedFilter = undefined
            }
        },

        hasEmptyFields (obj) {
            return Object.keys(obj).length === 0 ||
            Object.values(obj).some(value => value === undefined ||
            value === null ||
            value === '' ||
            (Array.isArray(value) && (value.length === 0 || value.some(v => v === undefined || v === null || v === ''))) ||
            (typeof value === 'object' && value !== null && this.hasEmptyFields(value)))
        },
        removeSelection (index) {
            this.triggers.splice(index, 1)
            this.$emit('update:triggers', this.triggers)
        },

        formatSelection (selection) {
            const { id, ...otherFields } = selection
            const labelFilter = this.allFilters?.find(item => item.id === (id?.id ? id.id : id))
            let formatted = ''
            if ((labelFilter?.kind === 'CATEGORICAL_ATTRIBUTE') ||
                (labelFilter?.kind === 'BOOLEAN_ATTRIBUTE') ||
                (labelFilter?.kind === 'DATETIME_ATTRIBUTE') ||
                (labelFilter?.kind === 'NUMERIC_ATTRIBUTE')) {
                formatted += `${selection.attribute} `
                formatted += Object.values(this.ATTRIBUTTE_TYPES).flat().find(item => item.value === id).label
                formatted += ' '
                formatted += selection?.values ? selection?.values.join(', ') : ''
                formatted += ' | '
                formatted += selection.activity ? `${selection.activity} ` : `${this.$t('visualization.filters.attributes.categoryAny')} `
            } else if ((labelFilter?.id === 'TRACE_DURATION_GREATER_THAN') ||
                        (labelFilter?.id === 'TRACE_DURATION_LESS_THAN') ||
                        (labelFilter?.id === 'ARC_DURATION_GREATER_THAN') ||
                        (labelFilter?.id === 'ARC_DURATION_LESSER_THAN') ||
                        (labelFilter?.id === 'ACTIVITY_DURATION_GREATER_THAN') ||
                        (labelFilter?.id === 'ACTIVITY_DURATION_LESS_THAN')) {
                formatted += this.$t(`visualization.filters.labels.${labelFilter.id}`)
                formatted += ': '
                formatted += moment.duration(selection.values).format("h[h] m[m] s[s]", { trim: "all" })
                if ((labelFilter?.id === 'ARC_DURATION_GREATER_THAN') ||
                (labelFilter?.id === 'ARC_DURATION_LESSER_THAN')) {
                    formatted += ' | '
                    formatted += selection.arc
                }
                if ((labelFilter?.id === 'ACTIVITY_DURATION_GREATER_THAN') ||
                (labelFilter?.id === 'ACTIVITY_DURATION_LESSER_THAN')) {
                    formatted += ' | '
                    formatted += selection.activity
                }
            } else if (labelFilter?.id === 'ACTIVITIES_DURATION_GREATER_THAN' ||
                labelFilter?.id === 'ACTIVITIES_DURATION_LESS_THAN' ||
                labelFilter?.id === 'ACTIVITIES_DURATION_LOOP_GREATER_THAN' ||
                labelFilter?.id === 'ACTIVITIES_DURATION_LOOP_LESS_THAN'
            ) {
                const firstValue = (labelFilter?.id === 'ACTIVITIES_DURATION_GREATER_THAN' || labelFilter?.id === 'ACTIVITIES_DURATION_LESS_THAN')
                    ? `${selection.values[0]}: ${selection.values[1]} `
                    : `${selection.values[0]} `
                const secondValue = (labelFilter?.id === 'ACTIVITIES_DURATION_GREATER_THAN' || labelFilter?.id === 'ACTIVITIES_DURATION_LESS_THAN')
                    ? `${selection.values[2]}: ${selection.values[3]}`
                    : `${selection.values[1]}`
                formatted += this.$t(`visualization.filters.labels.${labelFilter?.id}`)
                formatted += ': '
                formatted += moment.duration(selection.values[selection.values.length - 1]).format("h[h] m[m] s[s]", { trim: "all" })
                formatted += ' | '
                formatted += firstValue
                formatted += secondValue
            } else {
                formatted += this.$t(`visualization.filters.labels.${labelFilter?.id}`)
                formatted += ': '
                formatted += Object.entries(otherFields)
                    .map(([key, value]) => {
                        if (labelFilter?.kind === 'DATETIME' && value !== null) {
                            return moment(value[0]).format('DD/MM/YYYY hh:mm A')
                        }
                        if (Array.isArray(value)) {
                            return value.join(', ')
                        }
                        return value?.label || value
                    })
                    .join(' ')
            }
            return formatted
        },
        filterAttributesByType (attributes, type) {
            const typeList = Array.isArray(type) ? type : [type]
            const attributesArray = Object.entries(attributes)
            return attributesArray
                .filter(([_, value]) => typeList.includes(value))
                .map(([attribute]) => attribute)
        },
        async getAttributeValues () {
            const { processId } = this.$route.params
            const params = { filters: [], filterSets: [], arcs: true }

            try {
                const data = await apiRequest(Api().alerts.getAlertTriggers({ processId, params }))
                this.arcs = data.splitFilteredActivities.allArcs
                this.activities = data.splitFilteredActivities.allActivities
                this.attrValues = data.attributeValues
                const filteredAttributes = Object.fromEntries(
                    Object.entries(data.attributeTypes)
                        .filter(([key]) => !["Traceid", "Start", "End", "Activity"].includes(key)),
                )
                this.attributes = filteredAttributes
                this.categoricalAttributesWithValues = await this.obtainCategoricalAttributesWithValues(filteredAttributes)
            } catch (error) {
                notifyError(error)
            }
        },

        async obtainCategoricalAttributesWithValues (attributes) {
            try {
                await this.getCategoricalAttributes()
                return Object.fromEntries(
                    Object.entries(attributes).filter(([key]) => this.categoricalAttributes.some(att => att.name === key)),
                )
            } catch (error) {
                notifyError(error)
                return {}
            }
        },

        async getCategoricalAttributes () {
            const { processId } = this.$route.params
            const params = { filters: [], filterSets: [] }
            try {
                const res = await apiRequest(Api().visualizations.attributes({ processId, params }))
                this.categoricalAttributes = res || []
            } catch (error) {
                notifyError(error)
            }
        },
        async getFilters () {
            apiRequest(Api().visualizations.filters())
                .then((res) => {
                    this.allFilters = res
                    const kindsToInclude = ["ACTIVITIES", "ARCS", "DATETIME"]
                    const filtersByType = res.reduce((acc, item) => {
                        if (kindsToInclude.includes(item.kind)) {
                            return {
                                ...acc,
                                [item.kind]: [
                                    ...(acc[item.kind] || []),
                                    item,
                                ],
                            }
                        }
                        return acc
                    }, {})
                    const allLabels = { ...filtersByType, ATTRIBUTES: this.ATTR_FILTERS, DURATION: this.DURATION_FILTERS }
                    this.filters = Object.values(allLabels)
                        .flat()
                })
                .catch(notifyError)
        },
        resetSelectors () {
            const allFields = [
                'selectedActivity', 'selectedArc', 'selectedAttribute', 'selectedTime', 'selectedDate',
                'selectedDurationValue', 'selectedDurationUnit', 'firstActivity', 'secondActivity',
                'attrValue', 'durationConstraints', 'firstActivityConstraint', 'secondActivityConstraint',
                'attributeConstraints']
            allFields.forEach((field) => {
                this[field] = undefined
            })
        },
        validateRequired (value) {
            if (this.noTriggers && !value && this.triggers.length < 1) {
                return this.$t('booster.alerts.definition.createScreen.triggerConditions.noFilterSelected')
            }
            return true
        },
    },
}
</script>
<style scoped lang="scss">
.toggle {
  border: 1px solid $primary
}
.AlertTypes {
        padding: 0;
}
.q-select {
    min-width: 150px;
    width:fit-content;
    max-width:500px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.q-input {
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.Chip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.FormContainer {
    display:flex;
    align-items:center;
}
.AddIconStyle {
    display:flex;
    margin-top: 18px;
    cursor:pointer;
}
.MultipleFilterContainer {
    display: flex;
}
.MultipleFilterContainer .Field {
    flex: 1;
}
.FullWidth {
    flex: 1 1 100%;
}
.TwoInputs .Field {
    flex: 1 1 calc(50% - 5px);
}
</style>
