<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <div>
                    <WText
                        weight="semi"
                        :size="16"
                        class="mb-0"
                    >
                        {{ $t('visualization.zoomer.datasetTitle') }}
                    </WText>
                </div>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="q-pt-none q-px-lg Body">
                <QForm
                    class="align-center q-gutter-md q-ma-md"
                    @submit="createZoomer"
                >
                    <QInput
                        v-model="datasetName"
                        :label="$t('visualization.zoomer.datasetName')"
                        maxlength="100"
                        stackLabel
                        dense
                        outlined
                        :hint="`5 - 100 ${$t('general.characters')}`"
                        :rules="[
                            value => validators.betweenLength(value, 5, 100) || $t('validation.rangeLength', { attribute: $t('general.name'), min: 5, max: 100 }),
                            value => validators.allowedChars(value) || $t('validation.allowedChars', { attribute: $t('general.name') }),
                        ]"
                    />
                    <QBtn
                        class="SaveButtons"
                        noCaps
                        type="submit"
                        color="primary"
                        unelevated
                        :label="$t('visualization.filters.save')"
                    />
                </QForm>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'
import {
    Api,
    apiRequest,
    notify,
    notifySuccess,
    notifyError,
} from '@/api'

export default {
    name: 'ZoomerCreateModal',
    components: {},
    mixins: [validatorsMixin],
    props: {
        settings: VueTypes.object,
    },
    emits: ['createZoomer'],
    data () {
        return {
            datasetName: '',
            opened: false,
        }
    },
    computed: {
    },
    watch: {
    },
    beforeMount () {
    },
    mounted () {
    },
    methods: {
        open () {
            this.opened = true
        },
        close () {
            this.opened = false
        },
        createZoomer () {
            if (!this.settings.zoomerConfig ||
              (this.settings.zoomerConfig.groupedActivities.length <= 0 &&
              this.settings.zoomerConfig.excludedActivities.length <= 0)) {
                notify({ message: this.$t('visualization.zoomer.createDatasetError') })
                return
            }
            if (this.datasetName.length < 5 || this.datasetName.length > 100) {
                return
            }
            apiRequest(Api().datasets.createZoomer({ datasetId: this.$route.params.processId, params: { name: this.datasetName, zoomerConfig: this.settings.zoomerConfig } }))
                .then(() => {
                    notifySuccess(this.$t('visualization.zoomer.savedDatasetNotification'))
                })
                .catch(notifyError)
            this.close()
        },
    },
}
</script>
<style lang="scss" scoped>
.SaveButtons {
    width: 80px;
}

.SaveButtons {
    width: 80px;
}
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
.Header {
    padding-bottom:0px;
}
</style>
