export default {
    sections: {
        personal: 'Ajustes personales',
        security: 'Seguridad',
        apis: 'Gestionar tokens',
        token: 'Token disponible',
    },

    personal: {
        text1: `Edita tu información personal y configura la visibilidad de tu cuenta `,
        text2: `{name}`,
        text3: `.`,
    },

    security: {
        text: `Te enviaremos un correo electrónico con las instrucciones para restablecer tu contraseña.`,
        btn: 'Recuperar contraseña',
    },

    apis: {
        text: 'En esta vista puedes gestionar los tokens para trabajar e integrarte con InVerbis a través de su API REST.',
        generate: 'Generar token',
        cancel: 'Revocar token',
    },
    identificator: {
        success: 'Identificador copiado con éxito',
    },
    token: {
        tooltip: 'Copiar token al portapapeles',
        success: 'Token copiado con éxito',
        cancelled: 'Token revocado con éxito',
        modal: {
            title: 'Información del usuario',
            password: 'Contraseña',
            btn: 'Enviar',
        },
    },

    notifications: {
        personal: {
            text: 'Información personal actualizada correctamente.',
        },
        resetPassword: {
            text: 'Te enviaremos un mensaje de correo electrónico con tu nueva contraseña.',
        },
    },

    modals: {
        recovery: {
            title: 'Recuperar contraseña',
            text: '¿Seguro que quieres recuperar tu contraseña?',
        },
    },
}
