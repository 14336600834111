<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ $t('visualization.settings.sla.variantSlaModal.title') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body q-pt-none q-px-lg">
                <QForm
                    class="align-center q-gutter-md q-my-md"
                    @submit="handleSubmit"
                >
                    <div class="row mt-1">
                        <QSelect
                            v-model="variant"
                            class="Field"
                            outlined
                            dense
                            labelColor="primary"
                            color="primary"
                            :label="$t('visualization.settings.sla.variantSlaModal.selectVariant')"
                            :options="variants"
                            :disable="edit"
                        />
                    </div>
                    <div class="OptionsGroup row">
                        <InputNumeric
                            v-model="number"
                            :decimalNum="0"
                            :decimalStrictLimit="true"
                            :label="$t('visualization.settings.sla.number')"
                            standout
                            outlined
                            :min="0"
                            type="number"
                            class="col"
                        />
                        <QSelect
                            v-model="unit"
                            outlined
                            class="col"
                            labelColor="primary"
                            color="primary"
                            :label="$t('visualization.settings.sla.unit')"
                            :options="slaUnits"
                        />
                    </div>
                    <div class="justify-end row q-my-lg">
                        <QBtn
                            noCaps
                            unelevated
                            class="SubmitButton"
                            color="primary"
                            type="submit"
                        >
                            {{ $t('visualization.settings.sla.add') }}
                        </QBtn>
                    </div>
                </QForm>
            </q-card-section>
        </QCard>
    </QDialog>
</template>
<script>
import VueTypes from 'vue-types'
import { InputNumeric } from '@/components'

export default {
    name: 'VariantSlaModal',
    components: {
        InputNumeric,
    },
    props: {
        variants: VueTypes.array,
    },
    emits: [
        'addVariantSla',
        'editVariantSla',
    ],
    data () {
        return {
            opened: false,
            variant: '',
            edit: false,
            variantOptions: [],
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            number: 0,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    mounted () {
    },
    methods: {
        open (editVariantSla = undefined) {
            if (editVariantSla !== undefined) {
                this.variant = this.variants.find(v => v.value === editVariantSla.variantId)
                this.unit = { value: editVariantSla.unit, label: this.$t(`visualization.settings.sla.units.${editVariantSla.unit}`) }
                this.number = editVariantSla.value
                this.edit = true
                this.opened = true
            } else {
                this.edit = false
                this.reset()
                this.opened = true
            }
        },
        close () {
            this.opened = false
        },
        reset () {
            [this.variant] = this.variantOptions
            this.unit = { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') }
            this.number = 0
        },
        handleSubmit () {
            this.edit
                ? this.handleEditVariantSla()
                : this.handleAddVariantSla()
        },
        handleAddVariantSla () {
            this.$emit('addVariantSla', {
                variant: this.variant,
                number: this.number,
                unit: this.unit,
            })
        },
        handleEditVariantSla () {
            this.$emit('editVariantSla', {
                variant: this.variant,
                number: this.number,
                unit: this.unit,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.Card {
    .OptionsGroup {
        justify-content: space-between;
        gap: 10px;
    }

    .SubmitButton {
        padding-left: 25px;
        padding-right: 25px;
    }

    .Field {
        width: 100%;
        height: auto;
        &:deep(.q-field__control) {
                height: 50px;
                .q-field__control-container .q-field__native span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 34px;
                }
                .q-field__append {
                    height: 50px;
                }
        }
    }
}
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
.Header {
    padding-bottom:0px;
}
</style>
