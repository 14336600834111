<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ $t('visualization.traces.exportDialog.title') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body">
                <div class="q-pl-md">
                    {{ $t('visualization.traces.exportDialog.subtitle') }}
                </div>
                <div class="column q-ma-md">
                    <QTable
                        v-model:selected="selected"
                        class="my-sticky-header-table"
                        :rows="attrs"
                        :columns="columns"
                        rowKey="name"
                        flat
                        outlined
                        separator="none"
                        bordered
                        tableHeaderClass="bg-primary text-white"
                        :selectedRowsLabel="getSelectedString"
                        selection="multiple"
                        :rowsPerPageOptions="[0]"
                        hidePagination
                    >
                        <template #no-data="{ message }">
                            <div class="full-width row flex-center text-accent q-gutter-sm">
                                <span>
                                    {{ message }}
                                </span>
                            </div>
                        </template>
                    </QTable>
                </div>
                <div
                    class="DownloadButtons"
                >
                    <QBtn
                        :label="$t('visualization.traces.exportDialog.export')"
                        noCaps
                        color="primary"
                        unelevated
                        iconRight="download"
                        @click="handleExportData"
                    />
                    <QBtn
                        :label="$t('visualization.traces.exportDialog.exportExtended')"
                        noCaps
                        color="secondary"
                        unelevated
                        iconRight="download"
                        @click="handleExportExtendedData"
                    />
                </div>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'AttributeSelector',
    components: {},
    props: {
        attrs: VueTypes.array,
    },
    emits: ['export', 'exportExtended'],
    data () {
        return {
            opened: false,
            modelName: undefined,
            file: undefined,
            selected: [],
        }
    },
    computed: {
        columns () {
            return [{
                name: 'NAME', label: this.$t('visualization.traces.exportDialog.name'), field: 'name', align: 'left',
            },
            {
                name: 'TYPE', label: this.$t('visualization.traces.exportDialog.type'), field: 'type', align: 'left',
            }]
        },
    },
    mounted () {
    },
    methods: {
        open () {
            this.opened = true
        },
        close () {
            this.opened = false
        },
        handleExportData () {
            const exportAttrs = this.selected.map(attr => attr.name)
            this.$emit('export', exportAttrs)
            this.opened = false
        },
        handleExportExtendedData () {
            const exportAttrs = this.selected.map(attr => attr.name)
            this.$emit('exportExtended', exportAttrs)
            this.opened = false
        },
        getSelectedString () {
            return this.selected.length === 0
                ? ''
                : `${this.selected.length} ${this.$t('visualization.traces.exportDialog.attribute')}${this.selected.length > 1 ? 's' : ''}
                ${this.$t('visualization.traces.exportDialog.selected')} ${this.attrs.length}`
        },
    },
}
</script>
<style scoped lang="scss">
.my-sticky-header-table {
    height: 500px;
    max-height: 75vh;
    max-width: calc(100vw - 350px);
    &:deep(thead) {
        position: sticky;
        top: 0;
        background: white;
        z-index: 99;
    }
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
        background-color: #c1f4cd;
    }

    thead tr th {
        position: sticky;
        z-index: 1;
    }
    thead tr:first-child th {
        top: 0;
    }

    &.q-table--loading thead tr:last-child th {
        top: 48px;
    }
}

.DownloadButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}
.Card {
    overflow:hidden;
    flex-wrap:nowrap;
}
.Header {
    position:sticky;
    padding-bottom:0px;
}
.Body {
    overflow-y:auto;
}
</style>
