<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ title }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body q-pt-none q-px-lg">
                <div class="Wrapper">
                    <WText
                        v-if="text"
                        class="mt-0"
                    >
                        {{ text }}
                    </WText>
                    <slot />
                </div>
                <div class="flex row-right-center pb-1">
                    <QBtn
                        id="cancel"
                        class="ml-1"
                        outline
                        color="primary"
                        noCaps
                        :label="$t('actions.cancel')"
                        @click="handleClickCancel"
                    />
                    <QBtn
                        id="submit"
                        class="ml-1"
                        color="primary"
                        noCaps
                        :loading="loading"
                        :disable="loading"
                        @click="handleClickAccept"
                    >
                        {{ accept }}
                    </QBtn>
                </div>
            </q-card-section>
        </QCard>
    </QDialog>
</template>
<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import i18n from '@/lang/i18n'

export default {
    name: 'ModalRequest',
    components: {},
    props: {
        title: VueTypes.string,
        text: VueTypes.string,
        cancel: VueTypes.string.def(i18n.global.t("actions.cancel")),
        accept: VueTypes.string.def(i18n.global.t("actions.confirm")),
        loading: VueTypes.bool.def(false),
        autoClose: VueTypes.bool.def(true),
    },
    emits: ['onClose', 'onCancel', 'onAccept'],
    data () {
        return {
            opened: false,
        }
    },
    computed: {
    },
    methods: {
        open (form = {}) {
            this.opened = true
        },
        close () {
            this.opened = false
            this.$emit('onClose')
        },
        handleClickCancel () {
            if (this.autoClose) this.close()
            this.$emit('onCancel')
        },
        handleClickAccept () {
            if (this.autoClose) this.close()
            this.$emit('onAccept')
        },
    },
}
</script>
<style scoped lang="scss">
.Card {
    z-index: 99999;
    .Wrapper {
        margin: 30px 0;
    }
    .Header {
        padding-bottom:0px;
    }
}
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
</style>
