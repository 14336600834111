<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ title }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body q-pt-none q-px-lg">
                <NameDescForm
                    v-model="form"
                    :loading="loading"
                    :action="action"
                    :withoutDesc="withoutDesc"
                    @on-submit="handleSubmit"
                    @on-reset="close"
                />
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'
import { NameDescForm } from '@/forms'

const INITAL_STATE = {
    uuid: '',
    name: '',
    description: '',
}

export default {
    name: 'ModalNameDesc',
    components: {
        NameDescForm,
    },
    mixins: [validatorsMixin],
    props: {
        title: VueTypes.string,
        action: VueTypes.any,
        loading: VueTypes.bool.def(false),
        withoutDesc: VueTypes.bool.def(false),
    },
    emits: ['onSubmit'],
    data () {
        return {
            opened: false,
            form: { ...INITAL_STATE },
        }
    },
    methods: {
        open (form = {}) {
            this.opened = true
            if (form) this.form = { ...form }
        },
        close () {
            this.opened = false
        },
        handleSubmit (form) {
            this.opened = false
            this.$emit('onSubmit', this.form)
        },
    },
}
</script>
<style scoped lang="scss">
.Card {
    .NameDescForm {
        margin: 30px 0;
    }
    .Header {
        padding-bottom:0px;
    }
}
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
</style>
