export default {
    visualizationToBooster: 'Process Booster',
    boosterDisabledTooltip: 'You must configure the necessary conditions to access the Booster',
    customAnalysis: 'Custom Analysis',
    performanceBoosterTitle: 'Performance Booster',
    overview: {
        tab: 'Overview',
        description: 'Understand how your process currently performs',
        relevantNotDefined: 'Configure the relevant attributes to display the data',
        slaNotDefined: 'Configure the target duration conditions to display the data.',
        relevantAndSLANotDefined: 'Configure the relevant attributes and the target duration conditions to display the data.',
        caseCompletionNotDefined: 'Configure the case completion conditions to display the data.',
        relevantAndCaseNotDefined: 'Configure the relevant attributes and the case completion conditions to display the data.',
        slaAndCaseNotDefined: 'Configure the target duration conditions and the case completion conditions to display the data.',
        relevantAndSLAAndCaseNotDefined: 'Configure the relevant attributes, the target duration conditions and the case completion conditions to display the data.',
        costNotDefined: 'Configure the cost conditions to display the data.',
        relevantAndCostNotDefined: 'Configure the relevant attributes and the cost conditions to display the data.',
        slaAndCostNotDefined: 'Configure the target duration conditions and the cost conditions to display the data.',
        relevantAndSLAAndCostNotDefined: 'Configure the relevant attributes, the target duration conditions and the cost conditions to display the data.',
        noConditionsText: 'You can define the necessary conditions in the dataset settings.',
    },
    state: {
        performanceBy: 'Performance by ',
        rightClick: 'Right click on a bar to view graph',
        leadTimesBy: 'Lead times by ',
        timeSpentPerCase: 'Average time spent per case',
        performanceComparison: 'performance comparison',
        leadTimes: ' lead times',
        cases: ' cases',
        selectFilteringElements: 'Currently selected elements for resource filtering',
        evolutionOverTime: 'Performance evolution over time',
        overallPerformance: 'Overall performance by:',
        activity: 'Activity',
        transition: 'Transition',
        variant: 'Variant',
        months: 'months',
        weeks: 'weeks',
        all: 'All',
        below: 'Below target duration',
        intermediate: 'Intermediate',
        above: 'Above target duration',
        slaCompliance: 'Target duration compliance',
        noTraces: 'No traces found for the current filters',
        performanceByClick: 'Click a left chart bar to select',
    },
    prediction: {
        tab: 'Prediction',
        description: 'Find out how your process will perform',
        predictionHighlights: {
            name: 'Prediction highlights',
            cases: 'cases',
            ongoingCases: 'ongoing cases ',
            meetingSLA: 'in condition of ',
            meetingSLAColor: 'meeting target duration',
            missingSLAColor: 'under risk ',
            missingSLA: 'of missing target duration',
            exceeding: 'already ',
            exceedingColor: 'exceeding target duration',
        },
        predictedPerformance: {
            name: 'Predicted performance by ',
            risk: 'Risk estimation of ongoing predictions',
            riskLabel: 'Risk',
            duration: 'Predicted duration versus past duration',
            durationLabel: 'Dur. t.d.',
            distribution: 'Target duration performance distribution',
            distributionLabel: 'Distr. t.d.',
            past: 'Past',
            predicted: 'Predicted',
            finishedAbove: 'Finished above target duration',
            finishedUnder: 'Finished under target duration',
            finished: 'Finished',
            ongoingAbove: 'Ongoing above target duration',
            ongoingUnder: 'Ongoing under target duration',
            finishedOngoing: 'Finished - Ongoing',
            pastPredicted: 'Past - Predicted',
            ongoing: 'Ongoing',
            pastMonths: 'Past ',
            months: ' months',
        },
        ongoingCases: {
            showOnly: 'Show only for ',
            ongoingCaseList: 'Ongoing case list',
            state: 'State',
            caseId: 'Case ID',
            currentDuration: 'Current duration',
            estimatedDuration: 'Estimated duration',
            Good: 'Good',
            Risk: 'Risk',
            above: 'Above target duration',
            select: 'Please, select a value',

        },
        timeToMeet: {
            timeToMeet: 'Time to meet ',
            improvementRate: 'Minimum monthly improvement rate for meeting ',
            improvementRateSla: 'Minimum monthly improvement rate for ',
            improvementRateSecondPart: ' in ',
            improvementRateSlaLabel: 'target duration',
            past: 'Past ',
            months: ' months',
            bestPerforming: 'current best performing ',
        },
        analysisReportUnavailable:
            'The automatic prediction insights report could not be generated' +
            'because one or more of the selected relevant attributes are not found' +
            'associated with the cases currently in progress. Try changing the relevant attributes ' +
            'in settings.',
    },
    improvement: {
        tab: 'Simulation',
        description: 'Discover how to improve the performance of your process',
        activitiesImprove: 'Process activities to improve',
        transitionsImprove: 'Process transitions to improve',
        improvementResults: 'Improvement results on ',
        improvementTables: {
            rule1: 'Only numbers',
            rule2: '0-100',
            name: 'Name',
            currentImpact: 'Current Impact',
            improvement: 'Improvement',
            estimatedImpact: 'Estimated Impact',
        },
        improvementFigures: {
            title: 'Improvement figures',
            cases: ' cases',
            red: 'red ',
            green: ' green',
            become: 'that become',
            decrease: 'decrease',
            overallDuration: ' in overall duration',
            overallCosts: ' in overall costs',
            days: ' days',
            hours: 'hours',
            seconds: 'sec',
            currentPerformance: 'Current performance',
            improvedPerformance: 'Improved performance',
            slaCompliance: 'Target duration compliance',
            slaNonCompliance: 'Target duration non compliance',
        },
        improvementScope: {
            title: 'Improvement scope',
            add: 'Select and add attribute-value pairs to constrain the improvement scope',
            attributes: 'Attributes',
            values: 'Values',
        },
    },
    tabs: {
        overview: 'Overview',
        prediction: 'Prediction',
        improvement: 'Improvement',
    },
    d2t: {
        viewReport: 'View report',
        analysisReport: 'Analysis Report',
        copyToClipboard: 'Copy to clipboard',
        copied: 'Copied!',
    },
    alerts: {
        active: 'Active alerts',
        all: 'All alerts',
        severity: {
            high: 'High severity',
            medium: 'Medium Severity',
            low: 'Low severity',
        },
        tab: 'Alerts',
        description: 'Set up and check alerts on real time',
        filterBy: 'Filter by',
        newAlert: 'Set up new alert',
        lastDetected: 'Last detected: ',
        details: 'Details',
        dismiss: 'Dismiss',
        dismissAll: 'Dismiss all',
        detected: {
            noAlertEvaluations: 'No alert evaluations to show',
            lastDetected: 'Last detected',
            severityRiskLabels: {
                LOW: 'Low',
                MEDIUM: 'Medium',
                HIGH: 'High',
            },
            predictionRiskLabels: {
                LOW: 'Target duration under risk',
                MEDIUM: 'Target duration under risk',
                HIGH: 'Target duration exceeded',
            },
        },
        definition: {
            create: 'Set up new alert',
            configureAlert: 'Configure alert',
            removeAlert: 'Remove alert',
            remove: 'Remove selected',
            name: 'Name',
            severity: 'Severity',
            types: {
                ALL: 'All',
                STANDARD: 'Standard',
                PREDICTION: 'Prediction',
                TIME: 'Time',
            },
            period: 'Period',
            record: 'record',
            selected: 'selected of',
            createScreen: {
                newAlert: 'New alert definition',
                sendEmail: 'Send e-mail notification to',
                user: 'user\'s e-mail',
                other: 'different e-mail',
                notSend: 'do not send',
                notifyTo: 'Target e-mail address',
                cancel: 'Cancel',
                addAlert: 'Add alert',
                updateAlert: 'Update alert',
                notificationFrequency: {
                    label: 'Notification frequency',
                    everyUpdate: 'with every update',
                    daily: 'daily',
                    weekly: 'weekly',
                },
                notificationDay: {
                    label: 'Notification day',
                    monday: 'Mon',
                    tuesday: 'Tue',
                    wednesday: 'Wed',
                    thursday: 'Thu',
                    friday: 'Fri',
                    saturday: 'Sat',
                    sunday: 'Sun',
                },
                triggerConditions: {
                    label: 'Trigger conditions',
                    selectFilter: 'Filter',
                    selectActivities: 'Activities',
                    selectActivity: 'Activity',
                    selectAttribute: 'Attribute',
                    constraints: 'Constraints',
                    selectArcs: 'Arcs',
                    selectValue: 'Value',
                    selectDate: 'Date',
                    selectUnit: 'Unit',
                    selectTime: 'Time',
                    noAttributes: 'There are no attributes of this type',
                    occurrenceOrder: 'Occurrence order',
                    minValue: 'Min value: 1',
                    noFilterSelected: 'You must select at least one filter',
                },
            },
            tableColumn: {
                name: 'Name',
                severity: 'Severity',
                filterset: 'Filter Set',
                type: 'Type',
                notification: 'Notification',
            },
            timeUnit: {
                seconds: 'Seconds',
                minutes: 'Minutes',
                hours: 'Hours',
                days: 'Days',
                weeks: 'Weeks',
                months: 'Months',
            },
        },
        details: {
            label: 'Details',
            affectedCases: 'Affected cases',
            lastDetected: 'Last detected',
            triggerConditions: 'Trigger conditions',
            events: 'Events',
            selectCaseIdMessage: 'Please select a case ID to view its events',
            caseId: 'Case ID',
            viewOnTraceViewer: 'View on Trace Viewer',
        },
        notifications: {
            created: 'Alert succesfully created',
            updated: 'Alert succesfully updated',
            dismissSuccess: 'Evaluation successfully dismissed',
            dismissAllSuccess: 'Evaluations successfully dismissed',
            removeSuccess: 'Alert sucessfully removed',
            validateError: 'Please fill all the required fields properly',
        },
    },
}
