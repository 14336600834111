export default {
    sections: {
        personal: 'Personal Settings',
        security: 'Security',
        apis: 'Manage tokens',
        token: 'Available token',
    },

    personal: {
        text1: `Edit your personal information and visibility settings through your `,
        text2: `{name} `,
        text3: ` profile.`,
    },

    security: {
        text: `We will send a message with instructions to reset your password to your e-mail address.`,
        btn: 'Reset password',
    },

    apis: {
        text: 'Here you can easily manage your tokens',
        generate: 'Generate token',
        cancel: 'Revoke token',
    },
    identificator: {
        success: 'Identifier copied successfully',
    },
    token: {
        tooltip: 'Copy to clipboard',
        success: 'Token copied successfully',
        cancelled: 'Token successfully revoked',
        modal: {
            title: 'User information',
            password: 'Password',
            btn: 'Send',
        },
    },

    notifications: {
        personal: {
            text: 'Personal info saved successfully.',
        },
        resetPassword: {
            text: 'We have sent you a message with a new password to your e-mail address.',
        },
    },

    modals: {
        recovery: {
            title: 'Reset Password',
            text: 'Are you sure you want to reset your password?',
        },
    },
}
