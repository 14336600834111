export default {
    visualizationToBooster: 'Impulsionador de processos',
    boosterDisabledTooltip: 'É necessário configurar as condições necessárias para aceder ao Booster',
    customAnalysis: 'Análise personalizada',
    performanceBoosterTitle: 'Booster de desempenho',
    overview: {
        tab: 'Síntese',
        description: 'Compreender o desempenho atual do seu processo',
        relevantNotDefined: 'Configure os atributos relevantes para exibir os dados',
        slaNotDefined: 'Configurar as condições de duraçao meta para exibir os dados.',
        relevantAndSLANotDefined: 'Configure os atributos relevantes e as condições de duraçao meta para exibir os dados.',
        caseCompletionNotDefined: 'Configurar as condições de conclusão do caso para exibir os dados.',
        relevantAndCaseNotDefined: 'Configure os atributos relevantes e as condições de conclusão do caso para exibir os dados.',
        slaAndCaseNotDefined: 'Configurar as condições de duraçao meta e as condições de conclusão do caso para exibir os dados.',
        relevantAndSLAAndCaseNotDefined: 'Configure os atributos relevantes, as condições de duraçao meta e as condições de conclusão do caso para exibir os dados.',
        costNotDefined: 'Configurar as condições de custo para exibir os dados.',
        relevantAndCostNotDefined: 'Configure os atributos relevantes e as condições de custo para exibir os dados.',
        slaAndCostNotDefined: 'Configurar as condições de duraçao meta e as condições de custo para exibir os dados.',
        relevantAndSLAAndCostNotDefined: 'Configure os atributos relevantes, as condições de duraçao meta e as condições de custo para exibir os dados.',
        noConditionsText: 'É possível definir as condições necessárias nas configurações do conjunto de dados.',
    },
    state: {
        performanceBy: 'Performance by',
        rightClick: 'Clique com o botão direito do rato numa barra para ver o gráfico',
        leadTimesBy: 'Lead times by',
        timeSpentPerCase: 'Tempo médio gasto por caso',
        performanceComparison: 'comparação de desempenho',
        leadTimes: 'lead times',
        cases: 'casos',
        selectFilteringElements: 'Elementos atualmente seleccionados para filtragem de recursos',
        evolutionOverTime: 'Evolução do desempenho ao longo do tempo',
        overallPerformance: 'Desempenho global por:',
        activity: 'Atividade',
        transition: 'Transição',
        variant: 'Variante',
        months: 'meses',
        weeks: 'semanas',
        all: 'Todos',
        below: 'Abaixo da duraçao meta',
        intermediate: 'Intermediário',
        above: 'Acima da duraçao meta',
        slaCompliance: 'Cumprimento da duraçao meta',
        noTraces: 'Não foram encontrados vestígios para os filtros actuais',
        performanceByClick: 'Clique numa barra do gráfico à esquerda para selecionar',
    },
    prediction: {
        tab: 'Previsão',
        description: 'Descubra o desempenho do seu processo',
        predictionHighlights: {
            name: 'A previsão destaca',
            cases: 'casos',
            ongoingCases: 'ongoing cases',
            meetingSLA: 'in condition of',
            meetingSLAColor: 'cumprindo a duraçao meta',
            missingSLAColor: 'under risk',
            missingSLA: 'de não cumprir a duraçao meta',
            exceeding: 'already',
            exceedingColor: 'excedendo a duraçao meta',
        },
        predictedPerformance: {
            name: 'Predicted performance by',
            risk: 'Estimativa de risco das previsões em curso',
            riskLabel: 'Risco',
            duration: 'Duração prevista versus duração passada',
            durationLabel: 'Dur. d.m.',
            distribution: 'Distribuição do desempenho da duraçao meta',
            distributionSla: 'Distr. d.m.',
            distributionLabel: 'Distr. d.m.',
            past: 'Passado',
            predicted: 'Previsto',
            finishedAbove: 'Terminou acima da duraçao meta',
            finishedUnder: 'Terminou abaixo da duraçao meta',
            finished: 'Concluído',
            ongoingAbove: 'Em curso acima da duraçao meta',
            ongoingUnder: 'Em curso abaixo da duraçao meta',
            finishedOngoing: 'Terminado - Em curso',
            pastPredicted: 'Passado - Previsto',
            ongoing: 'Em curso',
            pastMonths: 'Passado',
            months: 'meses',
        },
        ongoingCases: {
            showOnly: 'Show only for',
            ongoingCaseList: 'Lista de casos em curso',
            state: 'Estado',
            caseId: 'ID do caso',
            currentDuration: 'Duração atual',
            estimatedDuration: 'Duração estimada',
            Good: 'Bom',
            Risk: 'Risco',
            above: 'Acima da duraçao meta',
            select: 'Por favor, seleccione um valor',

        },
        timeToMeet: {
            timeToMeet: 'Hora de se encontrar',
            improvementRate: 'Taxa mínima de melhoria mensal para atender',
            improvementRateSla: 'Taxa mínima de melhoria mensal para',
            improvementRateSecondPart: 'em',
            improvementRateSlaLabel: 'duraçao meta',
            past: 'Passado',
            months: 'meses',
            bestPerforming: 'atual melhor desempenho',
        },
        analysisReportUnavailable:
            'Não foi possível gerar o relatório das informações preditivas automáticas ' +
            'porque um ou mais dos atributos relevantes selecionados estão em falta ' +
            'associados aos casos atualmente em curso. Tente alterar os atributos relevantes ' +
            'na configuração',
    },
    improvement: {
        tab: 'Simulação',
        description: 'Descubra como melhorar o desempenho do seu processo',
        activitiesImprove: 'Atividades do processo para melhorar',
        transitionsImprove: 'Transições do processo para melhorar',
        improvementResults: 'Resultados de melhoria em ',
        improvementTables: {
            rule1: 'Somente números',
            rule2: '0-100',
            name: 'Nome',
            currentImpact: 'Impacto Atual',
            improvement: 'Melhoria',
            estimatedImpact: 'Impacto Estimado',
        },
        improvementFigures: {
            title: 'Figuras de melhoria',
            cases: ' casos',
            red: 'vermelho ',
            green: ' verde',
            become: 'que se tornam',
            decrease: 'redução',
            overallDuration: ' na duração total',
            overallCosts: ' nos custos totais',
            days: ' dias',
            hours: 'horas',
            seconds: 'seg',
            currentPerformance: 'Desempenho atual',
            improvedPerformance: 'Desempenho melhorado',
            slaCompliance: 'Conformidade duraçao meta',
            slaNonCompliance: 'Não conformidade duraçao meta',
        },
        improvementScope: {
            title: 'Escopo de melhoria',
            add: 'Selecione e adicione pares atributo-valor para restringir o escopo da melhoria',
            attributes: 'Atributos',
            values: 'Valores',
        },
    },
    tabs: {
        overview: 'Visão geral',
        prediction: 'Previsão',
        improvement: 'Melhoria',
    },
    d2t: {
        viewReport: 'Ver relatório',
        analysisReport: 'Relatório de análise',
        copyToClipboard: 'Copiar para a área de transferência',
        copied: 'Copiado!',
    },
    alerts: {
        active: 'Alertas ativas',
        all: 'Todas as alertas',
        severity: {
            high: 'Alta severidade',
            medium: 'Severidade média',
            low: 'Baixa severidade',
        },
        tab: 'Alertas',
        description: 'Configurar e verificar alertas em tempo real',
        filterBy: 'Filtrar por',
        newAlert: 'Criar novo alerta',
        lastDetected: 'Última detecção: ',
        details: 'Detalhes',
        dismiss: 'Descartar',
        dismissAll: 'Descartar tudo',
        detected: {
            noAlertEvaluations: 'Não há avaliações de alertas a apresentar',
            lastDetected: 'Último caso detectado',
            severityRiskLabels: {
                LOW: 'Baixo',
                MEDIUM: 'Média',
                HIGH: 'Alto',
            },
            predictionRiskLabels: {
                LOW: 'Duraçao meta em risco',
                MEDIUM: 'duraçao meta em risco',
                HIGH: 'duraçao meta excedido',
            },
        },
        definition: {
            create: 'Configurar novo alerta',
            configureAlert: 'Configurar alerta',
            removeAlert: 'Remover alerta',
            remove: 'Remover selecionado',
            name: 'Nome do atributo',
            severity: 'Gravidade',
            types: {
                ALL: 'Todos',
                STANDARD: 'Padrão',
                PREDICTION: 'Previsão',
                TIME: 'Temporal',
            },
            period: 'Período',
            record: 'Registo',
            selected: 'Selecionado de',
            createScreen: {
                newAlert: 'Nova definição de alerta',
                sendEmail: 'Enviar notificação por correio eletrónico para',
                user: 'E-mail do utilizador',
                other: 'Correio eletrónico diferente',
                notSend: 'Não enviar',
                notifyTo: 'Endereço de correio eletrónico de destino',
                cancel: 'Cancelar',
                addAlert: 'Adicionar alerta',
                updateAlert: 'Modificar alerta',
                notificationFrequency: {
                    label: 'Frequência de notificação',
                    everyUpdate: 'A cada atualização',
                    daily: 'Diariamente',
                    weekly: 'Semanalmente',
                },
                notificationDay: {
                    label: 'Dia de notificação',
                    monday: 'Seg',
                    tuesday: 'Ter',
                    wednesday: 'Qua',
                    thursday: 'Qui',
                    friday: 'Sex',
                    saturday: 'Sáb',
                    sunday: 'Dom',
                },
                triggerConditions: {
                    label: 'Condições do gatilho',
                    selectFilter: 'Filtro',
                    selectActivities: 'Atividades',
                    selectActivity: 'Atividade',
                    selectAttributes: 'Atributo',
                    constraints: 'Restrições',
                    selectArcs: 'Arcos',
                    selectValue: 'Valor',
                    selectUnit: 'Unidade',
                    selectDate: 'Data',
                    selectTime: 'Horário',
                    noAttributes: 'Não existem atributos deste tipo',
                    occurrenceOrder: 'Ordem de ocorrência',
                    minValue: 'Valor mínimo: 1',
                    noFilterSelected: 'Você deve selecionar pelo menos um filtro.',
                },
            },
            tableColumn: {
                name: 'Nome do atributo',
                severity: 'Gravidade',
                filterset: 'Conjunto de filtros',
                type: 'Tipo de alerta',
                notification: 'Notificação',
            },
            timeUnit: {
                seconds: 'Segundos',
                minutes: 'Minutos',
                hours: 'Horas',
                days: 'Dias',
                weeks: 'Semanas',
                months: 'Meses',
            },
        },
        details: {
            label: 'Detalhes',
            affectedCases: 'Casos afetados',
            lastDetected: 'Última detecção',
            triggerConditions: 'Condições de ativação',
            events: 'Eventos',
            selectCaseIdMessage: 'Selecione um ID de caso para ver os seus eventos',
            caseId: 'ID do caso',
            viewOnTraceViewer: 'Ver no Visualizador de traços',
        },
        notifications: {
            created: 'Alerta criado com sucesso',
            updated: 'Alerta atualizado com sucesso',
            dismissSuccess: 'Avaliação descartada corretamente',
            dismissAllSuccess: 'Avaliações descartadas corretamente',
            removeSuccess: 'Alerta removida corretamente',
            validateError: 'Preencha corretamente todos os campos obrigatórios',
        },
    },
}
