<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ edit ? $t('visualization.settings.sla.activitySlaModal.editTitle') : $t('visualization.settings.sla.activitySlaModal.addTitle') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body q-pa-lg">
                <QForm
                    class="align-center q-gutter-md q-my-md"
                    @submit="handleAddActivitySla"
                >
                    <div class="row mt-1">
                        <QSelect
                            v-model="activity"
                            class="Field"
                            outlined
                            dense
                            labelColor="primary"
                            color="primary"
                            :disable="edit ? true : false"
                            :label="$t('visualization.settings.sla.activitySlaModal.selectActivity')"
                            :options="activityOptions"
                        />
                    </div>
                    <div class="OptionsGroup row">
                        <InputNumeric
                            v-model="value"
                            :decimalNum="0"
                            :decimalStrictLimit="true"
                            :label="$t('visualization.settings.sla.number')"
                            :min="1"
                            standout
                            outlined
                            type="number"
                            class="col"
                        />
                        <QSelect
                            v-model="unit"
                            outlined
                            class="col"
                            labelColor="primary"
                            color="primary"
                            :label="$t('visualization.settings.sla.unit')"
                            :options="slaUnits"
                        />
                    </div>
                    <div class="justify-end row q-my-lg">
                        <QBtn
                            noCaps
                            unelevated
                            color="primary"
                            class="SubmitButton"
                            type="submit"
                        >
                            {{ edit ? $t('visualization.settings.sla.edit') : $t('visualization.settings.sla.add') }}
                        </QBtn>
                    </div>
                </QForm>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import { InputNumeric } from '@/components'
import { Api, apiRequest, notifyError } from '@/api'

export default {
    name: 'ActivitySlaModal',
    components: {
        InputNumeric,
    },
    inject: ['App'],
    emits: [
        'addActivitySla',
    ],
    data () {
        return {
            opened: false,
            activity: '',
            activityOptions: [],
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            value: 0,
            edit: false,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    mounted () {
        this.getActivities()
    },
    methods: {
        open (editCustomSla = undefined) {
            if (editCustomSla !== undefined) {
                this.edit = true
                this.setValues(editCustomSla)
            } else {
                this.edit = false
                this.reset()
            }
            this.opened = true
        },
        close () {
            this.opened = false
        },
        reset () {
            [this.activity] = this.activityOptions
            this.unit = { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') }
            this.value = 0
        },
        setValues (editCustomSla) {
            [this.activity] = editCustomSla.elements
            this.unit = { value: editCustomSla.unit, label: this.$t(`visualization.settings.sla.units.${editCustomSla.unit}`) }
            this.value = editCustomSla.value
        },
        getActivities () {
            const { processId } = this.$route.params
            const params = {}
            apiRequest(Api().visualizations.activities({ processId, params }))
                .then((res) => {
                    this.activityOptions = res.map(activity => activity.name).sort()
                    const [firstActivity] = this.activityOptions
                    this.activity = firstActivity
                })
                .catch(notifyError)
        },
        handleAddActivitySla () {
            this.$emit('addActivitySla', {
                type: 'activity',
                elements: [this.activity],
                value: this.value,
                unit: this.unit.value,
                loopMode: '',
                sourceOccurrence: '',
                targetOccurrence: '',
            }, this.edit)
        },
    },
}
</script>
<style scoped lang="scss">
.Card {
    .OptionsGroup {
        justify-content: space-between;
        gap: 10px;
    }

    .SubmitButton {
        padding-left: 25px;
        padding-right: 25px;
    }

    .Field {
        width: 100%;
        height: auto;
        &:deep(.q-field__control) {
                height: 50px;
                .q-field__control-container .q-field__native span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 34px;
                }
                .q-field__append {
                    height: 50px;
                }
        }
    }
}
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
.Header {
    padding-bottom:0px;
}
.Body {
    padding-top:0px;
}
</style>
